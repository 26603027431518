import { APP_PATH, CHATBOX_VERSION } from 'src/Tools/Constants';
import { getBotLanguages, getBots, getConsultationSpaces, setCurrentBot } from 'src/Redux/Bot/actions';
import { getBotsList, getCurrentBot } from 'src/Redux/Bot/selectors';
import { getUserHost, getUserLang, getUserName } from 'src/Redux/User/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { BOT } from 'src/Redux/Bot/types';
import Chip from '../Chip/Chip';
import Header from './Header';
import { MenuItem } from './Style';
import { checkChatboxAvailability } from 'src/Redux/App/actions';
import { getLoadingStatus } from 'src/Redux/Loading/selectors';
import { upercaseFirstLetter } from 'src/Tools/Text';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../Contexts/UserContext';

export interface HeaderContainerProps {
  currentBot?: API_ATRIA.Schemas.DyduboxBotDTO | null;
  botsList?: API_ATRIA.Schemas.DyduboxBotDTO[];
  userlang: string;
  userName: string | null;
  accountMenu: any;
  botMenu: any;
  botListLoading: boolean;
}

const HeaderContainer = () => {
  const { casClient, encodedNextUrl } = useUserContext();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentBot = useSelector(getCurrentBot);
  const host = useSelector(getUserHost);
  const botsList = useSelector(getBotsList);
  const userName = useSelector(getUserName);
  const userlang = useSelector(getUserLang);
  const botListLoading = useSelector((state) => getLoadingStatus(state, BOT.GET_ALL.REQUEST));

  useEffect(() => {
    dispatch(checkChatboxAvailability.request(CHATBOX_VERSION));
  }, []);

  useEffect(() => {
    if (host && !currentBot?.botUUID) {
      dispatch(getBots.request());
    }
  }, []);

  const getBotRelatedData = (bot) => {
    dispatch(getBotLanguages.request(bot?.botUUID));
    dispatch(getConsultationSpaces.request(bot?.botUUID, bot?.defaultLanguage));
  };

  useEffect(() => {
    if (currentBot?.botUUID && host) {
      getBotRelatedData(currentBot);
    }
  }, [currentBot?.botUUID, host]);

  const handleSetCurrentBot = (item) => {
    if (currentBot?.botUUID !== item.botUUID) {
      dispatch(setCurrentBot(item));
      getBotRelatedData(item);
      navigate(APP_PATH.CONFIGURATIONS);
    }
  };

  const renderBot = (bot) => ({
    template: (_, options) => (
      <MenuItem onClick={options.onClick}>
        {upercaseFirstLetter(bot.name)}
        {bot?.botDefault && <Chip label={t('general.default')} />}
      </MenuItem>
    ),
    command: () => handleSetCurrentBot(bot),
  });

  const botMenu = botsList.length > 0 && [
    {
      label: t('header.active_bots').toUpperCase(),
      items: botsList.filter((bot) => bot.enableAsistant).map(renderBot),
    },
    {
      label: t('header.inactive_bots').toUpperCase(),
      items: botsList.filter((bot) => !bot.enableAsistant).map(renderBot),
    },
  ];

  const accountMenu = [
    {
      label: t('header.logout'),
      command: () => {
        console.log('headercontainer just before calling cas logout');
        casClient.logout(encodedNextUrl);
      },
    },
  ];

  const props: HeaderContainerProps = {
    currentBot,
    botsList,
    userlang,
    userName,
    botMenu,
    accountMenu,
    botListLoading,
  };

  return <Header {...props} />;
};

export default HeaderContainer;
