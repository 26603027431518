import Menu from '../Menu/Menu';
import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  padding: 15px 20px;
  background: ${({ theme }) => theme.background_main_grey};
  border-bottom: 1px solid ${({ theme }) => theme.color_grey__300};
  box-shadow: 0px 2px 4px #5060704d;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .divider {
    height: 5px;
  }

  .p-button {
    .p-button-label {
      font-size: 12px;
      color: 1px solid ${({ theme }) => theme.color_grey__500};
      color: ${({ theme }) => theme.color_grey__700};
    }
  }
`;

export const HeaderMenu = styled(Menu)`
  .p-menu-list {
    max-height: 300px;
    overflow: auto;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 7px 10px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.color_grey__200};
  }

  .chip {
    margin-left: 10px;
  }
`;
