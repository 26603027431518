import { Divider as PrimeDivider } from 'primereact/divider';
import styled from 'styled-components';

export const Divider = styled(PrimeDivider)`
  background: ${({ theme }) => theme.color_grey__400};

  &.p-divider-horizontal {
    margin-top: 21px;
    margin-bottom: 21px;
  }
`;
