import { Dialog as PrimeDialog } from 'primereact/dialog';
import styled from 'styled-components';

export const Dialog = styled(PrimeDialog)`
  max-width: 70vw;

  .main-container {
    width: 650px;
    min-width: 100%;
    max-width: 100%;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .p-dialog-title {
    font-size: 21px !important;
    margin-right: 40px;

    &:first-letter {
      text-transform: uppercase;
    }
  }
  .p-dialog-header-close-icon {
    font-size: 16px !important;
  }
`;
