import { createMetaLinkedObject, updateMetaLinkedObject } from 'src/Redux/Configurations/actions/connectorActions';
import { useDispatch, useSelector } from 'react-redux';

import { ActionProps } from 'src/Components/Actions/Actions';
import LinkedObject from 'src/Pages/Edition/Components/Forms/Connector/Meta/Modals/LinkedObject';
import { META_LINKED_OBJECT } from 'src/Redux/Configurations/types';
import { closeModal } from 'src/Redux/Modal/actions';
import { getLoadingStatuses } from 'src/Redux/Loading/selectors';
import { getSelectedConfig } from 'src/Redux/Configurations/selectors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface LinkedObjectContainerProps {
  type?: API_CONNECTOR.Schemas.MetaObjectDTO;
  data?: API_CONNECTOR.Schemas.MetaLinkedObjectDTO;
  guideUrl: string;
}

export interface LinkedObjectProps extends LinkedObjectContainerProps {
  objectId: string;
  setObjectId: (val: string) => void;
  accessToken: string;
  setAccessToken: (val: string) => void;
  actions: ActionProps[];
  guideUrl: string;
}

const LinkedObjectContainer = (props: LinkedObjectContainerProps) => {
  const { t } = useTranslation();
  const { data, type, guideUrl } = props;
  const [objectId, setObjectId] = useState(data?.objectId || '');
  const [accessToken, setAccessToken] = useState(data?.accessToken || '');
  const dispatch = useDispatch();
  const selectedConfig = useSelector(getSelectedConfig);

  const handleCloseModal = () => dispatch(closeModal());

  const handleUpdate = () =>
    data?.id &&
    dispatch(
      updateMetaLinkedObject.request(selectedConfig.id, data?.id, {
        ...data,
        objectId,
        accessToken,
      })
    );

  const handleCreate = () =>
    type && dispatch(createMetaLinkedObject.request(selectedConfig.id, { objectId, object: type, accessToken }));

  const handleValidate = () => (data ? handleUpdate() : handleCreate());

  const isLoading = useSelector((state) =>
    getLoadingStatuses(state, [META_LINKED_OBJECT.CREATE_ONE.REQUEST, META_LINKED_OBJECT.UPDATE_ONE.REQUEST])
  );

  const actions: ActionProps[] = [
    {
      label: t('general.cancel'),
      className: 'p-button-text p-button-secondary',
      icon: 'icon-close',
      onClick: handleCloseModal,
    },
    {
      label: t('general.update'),
      icon: 'icon-check',
      disabled: objectId?.length < 1 || accessToken?.length < 1,
      onClick: handleValidate,
      loading: isLoading,
    },
  ];

  const modalProps: LinkedObjectProps = {
    data,
    objectId,
    setObjectId,
    accessToken,
    setAccessToken,
    actions,
    guideUrl,
    type,
  };

  return <LinkedObject {...modalProps} />;
};

export default LinkedObjectContainer;
