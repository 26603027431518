import CodeEditor from '@uiw/react-textarea-code-editor';
import styled from 'styled-components';

export const CustomEditor = styled(CodeEditor)`
  font-size: 14px;
  font-family: verdana;
  background: ${({ theme }) => theme.color_white};
  overflow: scroll !important;
`;

export const ActionsButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const EditorToggle = styled.div`
  cursor: pointer;
  display: flex;
  border-radius: 18px;
  color: ${({ theme }) => theme.color_grey__600};
  border: 1px solid ${({ theme }) => theme.color_grey__600};
  overflow: hidden;

  > div {
    margin: 1px;
    padding: 3px 30px;
    border-radius: 18px;
    transition: all 0.3s;
    transition-property: color, background-color;
  }

  .active {
    border-radius: 18px;
    background: black;
    background: ${({ theme }) => theme.color_grey__600};
    color: ${({ theme }) => theme.color_white};
  }
`;

export const PublishPanel = styled.div`
  width: 50%;
  .published {
    .heading {
      color: ${({ theme }) => theme.color_confirmation};
    }
  }
  .hasUpdate {
    .heading {
      color: ${({ theme }) => theme.color_warning};
    }
  }
  .actionWrapper {
    .inegration-menu {
      color: ${({ theme }) => theme.color_information};
      .p-button-label {
        font-size: 14px;
      }
    }
    .title {
      font-size: 14px;
      font-weight: normal;
      color: ${({ theme }) => theme.color_grey__500};
    }
  }
  .bundle {
    .url {
      font-size: 12px;
      padding: 15px 15px 20px 15px;
      border-radius: 6px;
      flex-direction: column;
      align-items: center;
      background: ${({ theme }) => theme.color_grey__200};
      border: 1px solid ${({ theme }) => theme.color_grey__400};
    }
    .actions {
      margin: 3px 0;
      justify-content: flex-start;
    }
  }
`;
