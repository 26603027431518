import { Button as PrimeButton } from './Style';
import { ButtonProps as PrimeButtonProps } from 'primereact/button';

export interface ButtonProps extends PrimeButtonProps {
  label?: string;
  title?: string;
  type?: 'button' | 'submit' | 'reset';
  icon?: string;
  className?: string;
  hidden?: boolean;
  iconPos?: 'top' | 'bottom' | 'left' | 'right';
  onClick?: (e: any) => void;
  disabled?: boolean;
  loading?: boolean;
  tooltip?: string;
  tooltipOptions?: any;
  key?: any;
}

const Button = ({
  label,
  onClick,
  loading,
  disabled,
  icon,
  title,
  className,
  tooltip,
  tooltipOptions,
  iconPos,
  type = 'button',
}: ButtonProps) => {
  const props = {
    type,
    label,
    onClick,
    disabled,
    loading,
    icon,
    title,
    className,
    tooltip,
    tooltipOptions,
    iconPos,
  };
  return <PrimeButton {...props} />;
};

export default Button;
