import * as Types from './types';

import { produce } from 'immer';
import { Modal } from '../../../typings/states';

const initialState: Modal = {
  actions: null,
  body: null,
  isOpen: false,
  title: '',
  options: {},
};

const ModalReducer = (state = initialState, { type, payload }: Redux.Action): Modal =>
  produce(state, (draft) => {
    switch (type) {
      case Types.SET_MODAL_OPEN:
        draft.actions = payload.actions;
        draft.body = payload.body;
        draft.isOpen = true;
        draft.title = payload.title;
        draft.options = payload.options;
        break;

      case Types.SET_MODAL_TITLE:
        draft.title = payload.title;
        break;

      case Types.SET_MODAL_CLOSE:
        draft.actions = null;
        draft.body = null;
        draft.isOpen = false;
        draft.title = '';
        draft.options = {};
        break;

      default:
        return draft;
    }
  });

export default ModalReducer;
