import { getConsultationSpace, getLanguages } from 'src/Redux/Bot/selectors';

import ActionWrapper from 'src/Components/ActionWrapper/ActionWrapper';
import Chip from 'src/Components/Chip/Chip';
import ControlledDropdown from 'src/Components/Dropdown/ControlledDropdown';
import { Divider } from 'src/Components/Divider/Style';
import { InformationsProps } from '../Informations';
import { InnerContent } from 'src/Pages/Edition/Style';
import { upercaseFirstLetter } from 'src/Tools/Text';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface AdditionalProps extends InformationsProps {}

const Additional = ({ watch, control, selectedConfig }: AdditionalProps) => {
  const { t } = useTranslation();

  const consultationSpace = useSelector(getConsultationSpace);
  const botLanguages = useSelector(getLanguages);

  const spaces = consultationSpace?.map((space) => ({
    label: space.name,
    value: space.name,
    id: space.consultationId?.value,
  }));

  const languages = botLanguages?.map((language) => ({
    label: language.name,
    value: language.name,
  }));

  const configType = upercaseFirstLetter(selectedConfig.type || '');

  return (
    <InnerContent>
      <ActionWrapper
        title={t('edition.additionalInfo.field.consultationSpace.title', {
          configType,
        })}
        body={
          spaces?.length > 1 ? (
            <ControlledDropdown
              options={spaces}
              placeholder={t('edition.additionalInfo.field.consultationSpace.placeholder')}
              value={watch('consultationSpace')}
              control={control}
              name='consultationSpace'
            />
          ) : (
            <Chip label={watch('consultationSpace')} />
          )
        }
      />
      <Divider />
      <ActionWrapper
        title={t('edition.additionalInfo.field.languages.title', {
          configType,
        })}
        body={
          languages?.length > 1 ? (
            <ControlledDropdown
              options={languages}
              placeholder={t('edition.additionalInfo.field.languages.placeholder')}
              value={watch('language')}
              control={control}
              name='language'
            />
          ) : (
            <Chip label={watch('language')} />
          )
        }
      />
    </InnerContent>
  );
};

export default Additional;
