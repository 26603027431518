import { Configurations } from '../../../typings/states';

const getState = (state): Configurations => state?.Configurations;

export const getConfigurationsList = (state) => getState(state)?.list;

export const getSelectedConfig = (state) => getState(state)?.selected;

export const getImagesData = (state) => getState(state)?.imagesData;

export const getMessageError = (state) => getState(state)?.messageError;

export const getCurrentConfigById = (state, id) => getConfigurationsList(state)?.find((config) => config.id === id);

/** WEBSITE */
export const getSelectedContent = (state) => getSelectedConfig(state)?.content;

/** META */
export const getSelectedMetaInfo = (state) => getSelectedConfig(state)?.info;

export const getSelectedMetaAppInfo = (state) => getSelectedMetaInfo(state)?.appInfo;

export const getSelectedObjectPermissions = (state) => getSelectedMetaAppInfo(state)?.objectPermissions;

export const getSelectedWebhookSubscriptions = (state) => getSelectedMetaAppInfo(state)?.webhookSubscriptions;

export const getSelectedMetaLinkedObjects = (state) => getSelectedMetaInfo(state)?.linkedObjects;
