import styled from 'styled-components';

export const MainContainer = styled.div`
  .file-input {
    display: none;
  }
`;

export const ConfigType = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;

  .type {
    width: 360px;
    padding: 20px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.color_grey__400};
    cursor: pointer;
    transition: all ease 0.5s;

    &:hover {
      box-shadow: 0px 2px 4px #5060704d;
      border-color: #7091d8;
    }

    & > div {
      padding: 5px 0;
      font-size: 16px;
      color: ${({ theme }) => theme.color_grey__600};

      &:last-child {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;

export const ConfigForm = styled.div`
  .input-wrapper {
    width: 650px;
    min-width: 100%;
    max-width: 100%;
  }
`;

export const FileUpload = styled.div`
  margin-bottom: 20px;

  .message.info {
    margin-bottom: 10px;
  }

  .message.warn {
    margin-top: 10px;
  }

  .label {
    font-size: 12px;
    color: ${({ theme }) => theme.color_grey__700};
  }

  .content {
    padding: 20px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.color_grey__400};
    display: flex;
    flex-direction: row;
    cursor: pointer;
    transition: all ease 0.5s;

    &:hover {
      box-shadow: 0px 2px 4px #5060704d;
      border-color: #7091d8;
    }

    .image {
      margin-right: 20px;
      img {
        width: 36px;
      }
    }

    .inner {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      align-items: center;

      .text {
        display: flex;
        flex-direction: column;
        .name {
          font-size: 16px;
          color: ${({ theme }) => theme.color_grey__600};
        }
        .size {
          font-size: 14px;
          color: ${({ theme }) => theme.color_grey__500};
        }
      }
      .upload-icon {
        width: 42px;
        height: 42px;
        border-radius: 42px;
        background: ${({ theme }) => theme.color_grey__500};
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
`;
