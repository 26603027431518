import { CONFIG_TYPES } from 'src/Tools/Constants';
import MetaContainer from './Meta/MetaContainer';
import TeamsContainer from './Teams/TeamsContainer';
import { formInterface } from '../EditionContainer';
import { getSelectedConfig } from 'src/Redux/Configurations/selectors';
import { useSelector } from 'react-redux';

const ConnectorContainer = (props: formInterface) => {
  const selectedConfig = useSelector(getSelectedConfig);
  const renderConnectorType = () => {
    switch (selectedConfig?.type) {
      case CONFIG_TYPES.TEAMS:
        return <TeamsContainer {...props} />;
      case CONFIG_TYPES.META:
        return <MetaContainer {...props} />;
      default:
        return;
    }
  };

  return <div className='connector-container'>{renderConnectorType()}</div>;
};

export default ConnectorContainer;
