import { Column as PrimeColumn } from 'primereact/column';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import styled from 'styled-components';

export const DataTable = styled(PrimeDataTable)`
  .p-datatable-table {
    margin: 0;
  }
`;

export const Column = styled(PrimeColumn)``;
