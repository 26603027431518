import Button, { ButtonProps } from '../Button/Button';
import { RestoreButtonWrapper } from './Style';

import { useTranslation } from 'react-i18next';
import RestorePublicationContainer from '../../Modals/RestorePublication/RestorePublicationContainer';
import { openModal } from '../../Redux/Modal/actions';
import { useDispatch } from 'react-redux';

interface RestoreButtonProps extends ButtonProps {
  configId: string;
  env: string;
  publications: API_BACKEND.Schemas.ConfigurationPublicationDTO[];
}

const RestoreButton = (props: RestoreButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { configId, env, publications } = props;

  const handleOnClick = () => {
    const title: string = t('edition.restore.modal.title', {
      env: env === 'PROD' ? t('general.publish_status.prod') : t('general.publish_status.preprod'),
    });
    const body: any = <RestorePublicationContainer configId={configId} publications={publications} />;
    dispatch(openModal({ title, body }));
  };

  const buttonProps = {
    ...props,
    onClick: handleOnClick,
    disabled: !publications || publications?.length < 2,
  };

  return (
    <RestoreButtonWrapper>
      <Button className={'p-button-success'} {...buttonProps} />
    </RestoreButtonWrapper>
  );
};

export default RestoreButton;
