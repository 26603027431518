import ControlledSlider from 'src/Components/Slider/ControlledSlider';
import ControlledSwitch from 'src/Components/Switch/ControlledSwitch';
import { DisplayProps } from '../Display';
import { Divider } from 'src/Components/Divider/Style';
import { InnerContent } from 'src/Pages/Edition/Style';
import { useTranslation } from 'react-i18next';

interface InteractionsProps extends DisplayProps {}

const Interactions = ({ control, watch }: InteractionsProps) => {
  const { t } = useTranslation();

  return (
    <InnerContent className='interactions'>
      <ControlledSwitch
        control={control}
        label={t('edition.display.field.minimize')}
        icon='minimize'
        name='content.header.actions.minimize'
      />
      <Divider />
      <ControlledSwitch
        control={control}
        label={t('edition.display.field.close')}
        icon='close-circle'
        name='content.header.actions.close'
      />
      <Divider />
      <ControlledSwitch
        control={control}
        label={t('edition.display.field.fullscreen')}
        icon='maximize'
        name='content.header.actions.expand'
      />
      <Divider />
      <ControlledSwitch
        control={control}
        label={t('edition.display.field.limitCaracters')}
        icon='keyboard'
        name='content.input.counter'
      />
      <ControlledSlider
        control={control}
        step={10}
        hidden={watch && !watch('content.input.counter')}
        label={t('edition.display.field.counter')}
        name='content.input.maxLength'
      />
    </InnerContent>
  );
};

export default Interactions;
