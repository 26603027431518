/* ----------------------------- */
/* ==Variables                   */
/* ----------------------------- */

//----------------------
// Utility colors
// ---------------------

export const color_action = '#3636B9';
export const color_information = '#2DB0FF';
export const color_critical = '#FF5252';
export const color_confirmation = '#2CBF87';
export const color_warning = '#F3B63B';

/* Purple */

export const color_purple__100 = '#E6E6F9';
export const color_purple__200 = '#D3D3F7';
export const color_purple__300 = '#8787D9';
export const color_purple__400 = '#152D62';

/* Grey */
export const color_white = '#FFFFFF';
export const color_grey__100 = '#F8FAFF';
export const color_grey__200 = '#F2F4FB';
export const color_grey__300 = '#D5DFEA';
export const color_grey__400 = '#B6C7D9';
export const color_grey__500 = '#74889D';
export const color_grey__600 = '#46525F';
export const color_grey__700 = '#33333B';
export const color_black = '#000000';

// Font families
export const fontFamilyBase = 'Roboto, sans-serif';
export const fontFamilySemiBold = 'Roboto Medium, sans-serif';
export const fontFamilyBold = 'Roboto Bold, sans-serif';
export const lineHeightBase = 1.5;
