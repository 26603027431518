import Input from '../Input/Input';
import styled from 'styled-components';

export const RestoreButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
`;

export const VersionInput = styled(Input)``;
