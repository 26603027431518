import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import rootReducers, { history } from 'src/Redux/rootReducer';

import { composeWithDevTools } from '@redux-devtools/extension';
import { createRouterMiddleware } from '@lagunovsky/redux-react-router';
import createSagaMiddleware from 'redux-saga';
import rootSagas from 'src/Redux/rootSaga';
import storageSession from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';

const configureStore = () => {
  const persistConfig = {
    blacklist: ['Modal', 'App', 'Loading', 'router', 'Configurations'],
    key: 'DYDU',
    storage: storageSession,
  };

  const sagaMiddleware = createSagaMiddleware();

  const pReducer = persistReducer(persistConfig, rootReducers);

  const middlewares = [thunk, sagaMiddleware, createRouterMiddleware(history)];

  const store = createStore(pReducer, composeWithDevTools(applyMiddleware(...middlewares)));

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSagas);

  return { persistor, store };
};

export default configureStore;

export const { store, persistor } = configureStore();

export type AppDispatch = typeof store.dispatch;
