import { InnerContent, RowWrapper } from 'src/Pages/Edition/Style';

import ActionWrapper from 'src/Components/ActionWrapper/ActionWrapper';
import ControlledInput from 'src/Components/Input/ControlledInput';
import Divider from 'src/Components/Divider/Divider';
import { TeamsStepProps } from 'src/Pages/Edition/Containers/Connector/Teams/TeamsContainer';
import { useTranslation } from 'react-i18next';

interface ApplicationProps extends TeamsStepProps {}

const Application = ({ control }: ApplicationProps) => {
  const { t } = useTranslation();

  return (
    <InnerContent className='application'>
      <ActionWrapper
        title={t('edition.application.preview.title')}
        subtitle={t('edition.application.preview.subtitle')}
        body={
          <RowWrapper>
            <ControlledInput
              control={control}
              label={t('edition.application.preview.shortname')}
              name='info.connectorManifestVariables.shortName'
              required
              rules={{
                maxLength: 30,
              }}
            />
            <ControlledInput
              control={control}
              label={t('edition.application.preview.author')}
              name='info.connectorManifestVariables.companyName'
              required
              rules={{
                maxLength: 32,
              }}
            />
            <ControlledInput
              control={control}
              type='textarea'
              label={t('edition.application.preview.shortdescription')}
              name='info.connectorManifestVariables.shortDescription'
              required
              rules={{
                maxLength: 80,
              }}
            />
          </RowWrapper>
        }
      />
      <Divider />
      <ActionWrapper
        title={t('edition.application.detailed.title')}
        subtitle={t('edition.application.detailed.subtitle')}
        body={
          <RowWrapper>
            <ControlledInput
              control={control}
              label={t('edition.application.detailed.longname')}
              name='info.connectorManifestVariables.fullName'
              required
              rules={{
                maxLength: 100,
              }}
            />
            <ControlledInput
              control={control}
              type='textarea'
              label={t('edition.application.detailed.longdescription')}
              name='info.connectorManifestVariables.fullDescription'
              required
              rules={{
                maxLength: 4000,
              }}
            />
          </RowWrapper>
        }
      />
    </InnerContent>
  );
};

export default Application;
