import { ColorPickerWrapper, ColorPlaceholder, ColorPicker as CustomColorPicker } from './Style';
import { useCallback, useEffect, useRef, useState } from 'react';

import Icon from 'src/Components/Icon/Icon';
import { isColor } from 'src/Tools/Text';
import { useClickAway } from 'react-use';

interface MessageProps {
  color: string;
  defaultColor?: string;
  className?: string;
  hexField?: boolean;
  onChange?: (color: string) => void;
}

const ColorPicker = ({ color, defaultColor, onChange, className, hexField }: MessageProps) => {
  const popover = useRef<any>();
  const [isOpen, toggle] = useState(false);
  const [colorError, setColorError] = useState(false);

  useEffect(() => {
    if (isColor(color)) {
      setColorError(true);
    } else {
      setColorError(false);
    }
  }, [color]);

  const close = useCallback(() => toggle(false), []);
  useClickAway(popover, close);

  const renderPicker = () =>
    isOpen && (
      <div className='popover' ref={popover}>
        <CustomColorPicker color={color} onChange={onChange} />
      </div>
    );

  const renderHexField = () =>
    hexField &&
    onChange &&
    color && (
      <div className={`hex-field ${colorError ? 'error' : ''}`}>
        <div className='title'>HEX</div>
        <input value={color} onChange={(e) => onChange(e.target.value)} />
      </div>
    );

  return (
    <ColorPickerWrapper className={`react-color-picker ${className || ''}`}>
      <div className='inner-content'>
        <ColorPlaceholder className='trigger' bgColor={colorError ? defaultColor : color} onClick={() => toggle(true)}>
          <Icon className='icon' icon='color-picker' color='white' />
        </ColorPlaceholder>
        {renderPicker()}
      </div>
      {renderHexField()}
    </ColorPickerWrapper>
  );
};

export default ColorPicker;
