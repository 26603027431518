import content from './content.json';
import contentPatchToNextVersion from './content-patch-to-next-version.json';
import contentSchema from './content-schema.json';
import theme from './theme.json';
import themePatchToNextVersion from './theme-patch-to-next-version.json';
import themeSchema from './theme-schema.json';
import { Release } from '../../../../../typings/states';
import nextRelease from '../chatbox_edge_2024-01-09/chatbox_edge_2024-01-09';

const release: Release = {
  version: 'chatbox_edge_2023-11-14',
  content: content,
  contentSchema: contentSchema,
  contentPatchToNextVersion: contentPatchToNextVersion,
  theme: theme,
  themeSchema: themeSchema,
  themePatchToNextVersion: themePatchToNextVersion,
  nextRelease: nextRelease,
};

export default release;
