import { ActionBody } from '../../Style';
import ActionWrapper from 'src/Components/ActionWrapper/ActionWrapper';
import Divider from 'src/Components/Divider/Divider';
import DotActions from 'src/Components/DotActions/DotActions';
import Heading from 'src/Components/Heading/Heading';
import Message from 'src/Components/Message/Message';
import { ObjectProps } from 'src/Pages/Edition/Containers/Connector/Meta/Pages/Dashboard/ObjectContainer';
import { Skeleton } from 'primereact/skeleton';
import { useTranslation } from 'react-i18next';

const Object = ({ data, dotActions }: ObjectProps) => {
  const { t } = useTranslation();

  const renderBody = () => (
    <ActionBody>
      {!data.validToken && <Message severity='error' text={t('general.invalidToken')} />}
      <DotActions actions={dotActions} />
    </ActionBody>
  );

  const renderInformations = () => (
    <div className='informations'>
      <Heading as='h5' title={t('general.pageInformation')} />
      <div className='row'>
        <div className='title'>{t('general.pageId')}</div>
        <div className='value'>{data.objectId}</div>
      </div>
      <div className={`row ${data.validToken ? '' : 'error'}`}>
        <div className='title'>{t('general.token')}</div>
        <div className='value'>{data.accessToken} </div>
      </div>
    </div>
  );

  const renderTitle = () => {
    switch (data?.object) {
      case 'whatsapp':
        return `${data?.verifiedName} (${data?.displayPhoneNumber})`;
      default:
        return data?.name;
    }
  };

  return (
    <div className='page-object'>
      <ActionWrapper
        image={data?.object !== 'whatsapp' && (data?.pictureUrl || <Skeleton className='image' />)}
        title={data?.validToken ? renderTitle() : <Skeleton width='100%' />}
        body={renderBody()}
      />
      <Divider />
      <div className='object-content'>{renderInformations()}</div>
    </div>
  );
};

export default Object;
