import { getSelectedMetaInfo, getSelectedObjectPermissions } from 'src/Redux/Configurations/selectors';

import Button from 'src/Components/Button/Button';
import FacebookLogin from '@greatsumini/react-facebook-login';
import Heading from 'src/Components/Heading/Heading';
import Icon from 'src/Components/Icon/Icon';
import LinkedObjectContainer from 'src/Pages/Edition/Containers/Connector/Meta/Modals/LinkedObjectContainer';
import { PagesProps } from '../Pages';
import { SidebarWrapper } from '../Style';
import { Skeleton } from 'primereact/skeleton';
import imageDoc from 'src/Assets/Images/img-doc-meta.png';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export interface SidebarProps extends PagesProps {
  waitingForCredentials?: boolean;
}

const Sidebar = (props: SidebarProps) => {
  const { initCreatePage, editCredentials, pageTypes, waitingForCredentials, guideUrl } = props;
  const metaInfo = useSelector(getSelectedMetaInfo);
  const objectPermissions = useSelector(getSelectedObjectPermissions);
  const { t } = useTranslation();

  const handleInitCreatePage = (type) => {
    initCreatePage(type, <LinkedObjectContainer type={type} guideUrl={guideUrl} />);
  };

  const openDocLink = () => window.open(guideUrl, '_blank');

  const renderCreationItem = (page) => {
    const appId = metaInfo?.clientId;
    const scope = objectPermissions?.find((permission) => permission.object === page.type)?.requiredPermissions || [];
    return (
      appId && (
        <FacebookLogin
          key={page.type}
          appId={appId}
          scope={scope.join()}
          onSuccess={() => handleInitCreatePage(page.type)}
          loginOptions={{
            auth_type: 'reauthorize',
            enable_profile_selector: true,
          }}
          render={({ onClick }) => (
            <li key={page.type} onClick={onClick}>
              <Icon className='cta-icon' icon={page.icon} />
              <div className='label'>{page.label}</div>
              <div className='cta'>
                {t('edition.meta.sidebar.page.cta')}
                <Icon className='cta-icon' icon='icon-arrow-right' />
              </div>
            </li>
          )}
        />
      )
    );
  };

  const renderCreationList = () => (
    <ul className='page-list'>
      {pageTypes
        .filter((type) => !type.disabled)
        .map((page) =>
          waitingForCredentials ? (
            <li key={page.type} className='skeleton'>
              <Skeleton shape='circle' size='1rem' className='mr-2' />
              <Skeleton width='100%' />
            </li>
          ) : (
            renderCreationItem(page)
          )
        )}
    </ul>
  );

  const renderAppInfo = () => (
    <div className='app-info'>
      {waitingForCredentials ? (
        <>
          <div>
            <Skeleton width='100%' />
          </div>
          <div>
            <Skeleton width='100%' />
          </div>
        </>
      ) : (
        <>
          <div>
            <div className='label'>{t('edition.meta.modal.appCredentials.clientId')}</div>
            <div className='value'>{metaInfo?.clientId}</div>
          </div>
          <div>
            <div className='label'>{t('edition.meta.modal.appCredentials.clientSecret')}</div>
            <div className='cta' onClick={() => editCredentials(true, true)}>
              {t('general.modify')}
              <Icon className='cta-icon' icon='icon-pencil' />
            </div>
          </div>
        </>
      )}
    </div>
  );

  const renderDocLink = () => (
    <div className='documentation'>
      <img src={imageDoc} alt='' />
      <div className='content'>
        <div className='title'>{t('edition.meta.sidebar.documentation')}</div>
        <div className='subtitle'>{t('edition.meta.sidebar.documentationText')}</div>
        <div className='action'>
          <Button
            onClick={openDocLink}
            label='Ouvrir la doc'
            className='p-button-text'
            iconPos='right'
            icon='icon-copy'
          />
        </div>
      </div>
    </div>
  );

  return (
    <SidebarWrapper>
      <Heading as='h4' title={t('edition.meta.sidebar.title')} />
      {renderCreationList()}
      {renderAppInfo()}
      {!waitingForCredentials && renderDocLink()}
    </SidebarWrapper>
  );
};

export default Sidebar;
