import docConnector from 'src/Assets/Images/doc-connector.png';
import styled from 'styled-components';

export const GuideDownloadButton = styled.div`
  background: url(${docConnector}) no-repeat -35px -20px #6f75ca;
  width: 619px;
  min-width: 619px;
  height: 87px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  margin-right: 100px;

  .caret {
    color: white;
    position: absolute;
    right: 40px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title,
  .subtitle {
    color: ${({ theme }) => theme.color_white};
    padding-left: 140px;
  }

  .title {
    padding-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }

  .subtitle {
    font-size: 12px;
  }
`;

export const OptionsPanel = styled.div`
  .optionField {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    margin-top: 10px;
  }
`;
