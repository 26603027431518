import * as Constants from 'src/Tools/Constants';

import { DisplayProps } from '../Display';
import { Divider } from 'src/Components/Divider/Style';
import { InnerContent } from 'src/Pages/Edition/Style';
import LocalStorage from 'src/Tools/LocalStorage';
import Switch from 'src/Components/Switch/Switch';
import { getLanguages } from 'src/Redux/Bot/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uploadTranslationJson } from 'src/Redux/Configurations/actions/websiteActions';
import currentRelease from 'src/Tools/releases/versions/current/current';

interface LanguagesProps extends DisplayProps {}

const Languages = ({ selectedConfig, setValue, watch }: LanguagesProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const languagesList = useSelector(getLanguages);
  const { application } = watch('content');

  const handleToggleLanguage = (lang: string, isActive: boolean) => {
    const langArray = application?.languages;
    const updatedLanguages = langArray?.includes(lang) ? [...(langArray || [])] : [...(langArray || []), lang];
    if (langArray?.indexOf(lang) > -1 && lang !== application?.defaultLanguage?.[0]) {
      setValue(
        'content.application.languages',
        isActive ? updatedLanguages : langArray?.filter((item) => item !== lang)
      );
    } else {
      setValue('content.application.languages', updatedLanguages);
    }

    if (isActive && selectedConfig?.id && lang) {
      dispatch(uploadTranslationJson.request(selectedConfig.id, currentRelease.translation, lang, false));
    }
  };

  const handleSetDefault = (lang: string) => {
    LocalStorage.setItem(Constants.DyduStorage.LOCALE, lang);
    setValue('content.application.defaultLanguage', [lang]);
    handleToggleLanguage(lang, true);
  };

  return (
    <InnerContent className='languages'>
      {languagesList.map((lang, index) => {
        const isDefaultLanguage: boolean = application?.defaultLanguage?.includes(lang.id);
        const isActive: boolean = application?.languages?.includes(lang.id) || false;
        return (
          <div key={lang.id}>
            <Switch
              label={lang.name}
              checked={isActive}
              image={`Icons/flags/${lang?.id}.png`}
              disabled={isDefaultLanguage}
              onChange={(event) => handleToggleLanguage(lang.id, event.value)}
              chip={{
                label: isDefaultLanguage ? t('general.default') : t('edition.display.field.setDefaultLanguage'),
                hightlight: isDefaultLanguage?.toString(),
                onClick: () => handleSetDefault(lang.id),
              }}
            />
            {index !== languagesList?.length - 1 && <Divider />}
          </div>
        );
      })}
    </InnerContent>
  );
};

export default Languages;
