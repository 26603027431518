import styled, { css } from 'styled-components';

import { MainLabel } from '../Style';
import { InputSwitch as PrimeInputSwitch } from 'primereact/inputswitch';

export const InputWrapper = styled.div`
  margin: 10px 10px 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  ${({ wide }: { wide?: boolean }) =>
    wide &&
    css`
      width: 100%;
      margin-right: 0%; ;
    `}
`;

export const Label = styled(MainLabel)`
  cursor: pointer;
  display: flex;
  width: 100%;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
    margin: 0 10px 0 0;
  }
`;

export const InputContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  align-items: center;

  .p-chip {
    margin-right: 30px;
    cursor: ${(onClick) => onClick && 'pointer'};
  }
`;

export const InputSwitch = styled(PrimeInputSwitch)`
  margin-left: 10px;
  transform: scale(1.6);

  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: ${({ theme }) => theme.color_information}!important;
    }
  }
`;
