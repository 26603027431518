import { ActionContainer, ActionContent, Label } from './Style';

import Icon from 'src/Components/Icon/Icon';

interface ActionWrapperProps {
  icon?: string;
  image?: any;
  title?: any;
  subtitle?: string;
  className?: string;
  body: any;
}

const ActionWrapper = ({ title, image, body, subtitle, icon, className = '' }: ActionWrapperProps) => {
  const renderImage = () => {
    if (!image) {
      return null;
    }
    return typeof image === 'string' ? <img className='image' src={image} /> : image;
  };
  const renderLabel = () =>
    title && (
      <Label className='label'>
        {icon && <Icon icon={icon} />}
        {renderImage()}
        <div className='text'>
          <div className='title'>{title}</div>
          <div className='subtitle'>{subtitle}</div>
        </div>
      </Label>
    );

  const renderContent = () => body && <ActionContent className='content'>{body}</ActionContent>;

  return (
    <ActionContainer className={`actionWrapper ${className}`}>
      {renderLabel()}
      {renderContent()}
    </ActionContainer>
  );
};

export default ActionWrapper;
