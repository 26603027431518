import styled, { css } from 'styled-components';

import { HexColorPicker } from 'react-colorful';

interface ColorPlaceholderProps {
  bgColor?: string;
  selected?: boolean;
}

export const ColorPickerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  .swatch {
    width: 28px;
    height: 28px;
    border-radius: 8px;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .trigger {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    .icon {
      color: ${({ theme }) => theme.color_white};
      margin-right: 10px;
      font-size: 13px;
    }
  }

  .popover {
    position: absolute;
    z-index: 10;
    top: calc(100% + 2px);
    left: 0;
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .hex-field {
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.color_grey__300};
    border-radius: 6px;
    .title {
      color: ${({ theme }) => theme.color_grey__300};
      padding: 5px;
      border-right: 1px solid ${({ theme }) => theme.color_grey__300};
    }

    input {
      padding: 5px;
    }

    &.error {
      border-color: ${({ theme }) => theme.color_critical};
    }
  }
`;

export const ColorPlaceholder = styled.div`
  width: 60px;
  height: 30px;
  border-radius: 30px;
  cursor: pointer;
  position: relative;
  background-color: ${({ bgColor }: ColorPlaceholderProps) => bgColor};

  ${({ selected }: ColorPlaceholderProps) =>
    selected &&
    css`
      &::after {
            display: block;
            content: 'L';
            position: absolute;
            right: 5px;
            top: calc(50% - 12px);
            width: 10px;
            height: 10px;
            color: white;
            font-family: arial;
            font-size: 19px;
            transform: scaleX(-1) rotate(-46deg);
        }
      }
    `}
`;

export const ColorPicker = styled(HexColorPicker)``;
