import styled from 'styled-components';

export const HeadingWrapper = styled.div`
  margin: 0 10px 14px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .text {
    display: flex;
    flex-direction: row;
  }

  h1,
  h2,
  h3,
  h4 {
    padding: 0;
    margin: 0;
  }

  h4 {
    font-size: 21px;
  }

  h5 {
    font-size: 14px;
  }

  img {
    max-width: 23px;
    margin: 0 10px 0 0;
  }
`;
