import * as Actions from './actions/configurationsActions';
import * as Types from './types';

import { all, call, put, select, spawn, takeLatest } from 'typed-redux-saga';

import { ApiProps } from '@dydu_ai/dydu-api';
import connectorSagas from './sagas/connectorSagas';
import { getAtriaHost } from 'src/Redux/User/selectors';
import { getClientApi } from 'src/Tools/Api';
import { toastError } from 'src/Tools/Toast';
import websiteSaga from './sagas/websiteSagas';

/**
 * API CALLS
 */

/**
 * Get Connector configurations list
 * @param botData
 * @returns
 */
function* getAllConfigurations({ payload: { botData } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const host = yield select(getAtriaHost);
    const { data: connector } = yield* call(Client.connector.getConnectorConfigurationsList, botData.botUUID);
    const { data: website } = yield* call(Client.backend.getWebsiteConfigurationsList, botData.botUUID, host);
    yield put(Actions.getAllConfigurations.success([...connector, ...website]));
  } catch (error) {
    yield put(Actions.getAllConfigurations.failure(error));
    toastError(error);
  }
}

/**
 * Configurations Saga
 */
export default function* configurationsSaga() {
  try {
    yield all([
      /** API Calls */
      takeLatest(Types.CONFIGURATIONS.GET_ALL.REQUEST, getAllConfigurations),
      /** Config Types Sagas */
      spawn(websiteSaga),
      spawn(connectorSagas),
    ]);
  } catch (error) {
    toastError(error);
  }
}
