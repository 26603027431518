import { ChipWrapper, Chip as PrimeChip } from './Style';

interface ChipProps {
  label?: string | number;
  className?: string;
  icon?: string;
  hightlight?: string;
  onClick?: () => void;
}

const Chip = ({ label, icon, onClick, className = '', hightlight = 'true' }: ChipProps) => (
  <ChipWrapper className={`chip ${className}`} onClick={onClick} hightlight={hightlight}>
    <PrimeChip className='error-message' icon={icon} label={label?.toString()} />
  </ChipWrapper>
);

export default Chip;
