import Loader from 'src/Components/Loader/Loader';
import { useLayoutEffect } from 'react';
import { useUserContext } from '../../Contexts/UserContext';

/**
 * this page is referenced by CAS as the front logout url for Channels
 */
const Logout = () => {
  console.log('logout displayed ' + window.location.href);
  const { casClient, encodedNextUrl } = useUserContext();

  useLayoutEffect(() => {
    console.log('logout with ' + encodedNextUrl);
    casClient.logout(encodedNextUrl);
  }, []);

  return <Loader loading={true} />;
};

export default Logout;
