import styled, { css } from 'styled-components';

export const DotActionsContainer = styled.div`
  position: relative;
  z-index: 4;
`;

interface DotTriggerProps {
  open: boolean;
  hasNotification: boolean;
}

export const DotTrigger = styled.div`
  position: relative;
  display: flex;
  border-radius: 36px;
  width: 36px;
  height: 36px;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: rgba(112, 145, 216, 0.1);
  }

  ${({ open }: DotTriggerProps) =>
    open &&
    css`
      background: rgba(112, 145, 216, 0.1);
    `}

  ${({ hasNotification }: DotTriggerProps) =>
    hasNotification &&
    css`
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        right: 3px;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background: ${({ theme }) => theme.color_critical};
      }
    `}
`;

export const DotActionsListWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 100%;
  z-index: 10;
`;

export const DotActionsList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  margin-right: 5px;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #414a5526;
`;

interface DotActionsListItemProps {
  withSeparator?: boolean;
  type?: string;
  disabled?: boolean;
  loading?: boolean | string;
}

export const DotActionsListItem = styled.li`
  height: 40px;
  color: #46525f;
  padding: 0 20px;
  white-space: nowrap;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  &:hover {
    background: rgba(112, 145, 216, 0.1);
    color: #41479b;
  }

  ${({ withSeparator }: DotActionsListItemProps) =>
    withSeparator &&
    css`
      margin-bottom: 10px;

      &:after {
        display: block;
        content: '';
        margin: 5px;
        background: white;
        border-bottom: 1px solid #d5dfea;
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
      }
    `}

  ${({ type }: DotActionsListItemProps) =>
    type === 'error' &&
    css`
      color: rgba(255, 82, 82, 1);

      &:hover {
        background: rgba(255, 82, 82, 0.1);
        color: #ff5252;
      }

      ${({ disabled }: DotActionsListItemProps) =>
        disabled &&
        css`
          color: rgba(255, 82, 82, 0.5) !important;
          cursor: default;

          &:hover {
            color: rgba(255, 82, 82, 0.5) !important;
          }
        `}
    `}

  ${({ disabled }: DotActionsListItemProps) =>
    disabled &&
    css`
      color: rgba(65, 71, 155, 0.5);
      cursor: default;

      &:hover {
        background: initial;
        color: rgba(65, 71, 155, 0.5);
      }
    `}


  ${({ loading }: DotActionsListItemProps) =>
    loading &&
    css`
      cursor: default;
    `}
`;

export const LoadingWording = styled.div`
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\\2026'; /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
`;
