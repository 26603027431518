import * as Types from './types';

import { action } from 'src/Tools/Redux';

export const setUserData: Redux.ActionFactory = (data) => action(Types.SET_USER_DATA, { data });

export const setAtriaHost: Redux.ActionFactory = (data: { host: string; proxy?: string }) =>
  action(Types.SET_ATRIA_HOST, data);

export const setLogout: Redux.ActionFactory = () => action(Types.LOGOUT);
