import { EmptyListContainer, Header } from '../Style';

import { ConfigurationsContainerProps } from '../Containers/ConfigurationsContainer';
import Heading from 'src/Components/Heading/Heading';
import PanelAction from './PanelAction';
import { useTranslation } from 'react-i18next';

const EmptyList = ({ importConfig }: ConfigurationsContainerProps) => {
  const { t } = useTranslation();

  return (
    <EmptyListContainer>
      <Header direction='column'>
        <Heading title={t('emptyConfig.heading.title')} as='h3' />
        <Heading title={t('emptyConfig.heading.subtitle')} as='h4' />
      </Header>
      <div className='options'>
        <PanelAction type='newConfig' />
        <PanelAction type='import' importConfig={importConfig} />
      </div>
    </EmptyListContainer>
  );
};

export default EmptyList;
