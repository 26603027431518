import { useEffect } from 'react';

import Loader from 'src/Components/Loader/Loader';
import { useUserContext } from '../../Contexts/UserContext';
import { encodedServiceUrl } from '../../Tools/Api';

const Auth = () => {
  console.log('auth displayed ' + window.location.href);
  const { casClient } = useUserContext();

  useEffect(() => {
    casClient.checkSession(true, encodedServiceUrl);
  }, []);

  return (
    <div className='auth-page'>
      <Loader loading={true} />
    </div>
  );
};

export default Auth;
