import { useEffect, useRef, useState } from 'react';

import Button from 'src/Components/Button/Button';
import { CustomEditor } from '../Style';
import { DisplayProps } from '../Display';
import { InnerContent } from 'src/Pages/Edition/Style';
import { copyToClipboard } from 'src/Tools/Text';
import { useTranslation } from 'react-i18next';

interface CssEditorProps extends DisplayProps {}

const CssEditor = ({ setValue, watch }: CssEditorProps) => {
  const { t } = useTranslation();
  const cssEditorRef = useRef<any>(null);

  const [css, setCss] = useState('');

  useEffect(() => {
    setCss(watch('style'));
  }, [watch('style')]);

  const hasCodeChanged = watch('style')?.trim() !== css?.trim();

  const handleSetCss = (event) => setCss(event.target.value);

  const handleApplyCss = () => setValue('style', css);

  const handleCancel = () => setCss(watch('style'));

  const handleCopyToClipBoard = () => copyToClipboard(css);

  const renderActions = () => (
    <div className='action-buttons'>
      <Button
        label={t('general.cancel')}
        onClick={handleCancel}
        className='p-button-text'
        icon='icon-eraser'
        disabled={!hasCodeChanged}
      />
      <Button
        label={t('general.copy')}
        onClick={handleCopyToClipBoard}
        className='p-button-text'
        icon='icon-copy'
        disabled={css?.length === 0}
      />
      <Button
        label={t('general.apply')}
        iconPos='right'
        icon='icon-code'
        onClick={handleApplyCss}
        disabled={!hasCodeChanged}
      />
    </div>
  );

  return (
    <InnerContent data-color-mode='light' className='css-editor'>
      <CustomEditor
        key='cssEditor'
        ref={cssEditorRef}
        language='css'
        minHeight={200}
        value={css}
        onChange={handleSetCss}
      />
      {renderActions()}
    </InnerContent>
  );
};

export default CssEditor;
