import * as Actions from './actions';
import * as Types from './types';

import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { toastError } from 'src/Tools/Toast';

import { ApiProps } from '@dydu_ai/dydu-api';
import { encodeBase64 } from 'src/Tools/Text';
import { getClientApi } from 'src/Tools/Api';
import { getUserProxy } from '../User/selectors';

/**
 * Get Account info
 * @param param0
 */
function* getAccountInfo({ payload: { userName } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const { data } = yield* call(Client.security.getAccountInfo, userName);
    console.log('🚀 ~ file: sagas.ts ~ line 30 ~ function*getAccountInfo ~ data', data);

    yield put(Actions.getAccountInfo.success());
  } catch (error: any) {
    console.log('🚀 ~ file: sagas.ts ~ line 34 ~ function*getAccountInfo ~ error', error);
    yield put(Actions.getAccountInfo.failure(error));
    toastError(error);
  }
}

/**
 * Check if CDN Url exist or not
 * @param param0
 */
function* checkChatboxAvailability({ payload: { version } }) {
  try {
    const proxy = yield select(getUserProxy);
    const Client: ApiProps = yield call(getClientApi);
    yield* call(Client.backend.checkChatboxAvailability, { version, ...(proxy && { proxy: encodeBase64(proxy) }) });
    yield put(Actions.checkChatboxAvailability.success());
  } catch (error) {
    yield put(Actions.checkChatboxAvailability.failure(error));
    toastError(error);
  }
}

export default function* appSaga() {
  try {
    yield all([
      takeLatest(Types.ACCOUNT.GET.REQUEST, getAccountInfo),
      takeLatest(Types.CHATBOX.CHECK.REQUEST, checkChatboxAvailability),
    ]);
  } catch (error: any) {
    toastError(error);
  }
}
