import Actions from 'src/Components/Actions/Actions';
import Input from 'src/Components/Input/Input';
import { MainContainer } from './Style';
import { UpdateSlugProps } from './UpdateSlugContainer';
import { useTranslation } from 'react-i18next';

const UpdateSlug = ({ actions, configSlug, setConfigSlug }: UpdateSlugProps) => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <Input
        value={configSlug}
        onChange={(e) => setConfigSlug(e.target.value || '')}
        placeholder={t('configurations.import_config_slug_placeholder')}
        label={t('configurations.import_config_slug_label')}
      />
      <Actions actions={actions} />
    </MainContainer>
  );
};

export default UpdateSlug;
