import { CONFIG_TYPES, UPLOAD_TYPE } from 'src/Tools/Constants';
import { CONNECTOR_CONFIGURATION, WEBSITE_CONFIGURATION } from 'src/Redux/Configurations/types';
import {
  createWebsiteConfiguration,
  importWebsiteConfiguration,
} from 'src/Redux/Configurations/actions/websiteActions';
import { getUserLang, getUserName } from 'src/Redux/User/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';

import { ActionProps } from 'src/Components/Actions/Actions';
import CreateForm from './Form/CreateForm';
import CreateType from './Form/CreateType';
import { MainContainer } from './Style';
import { createConnectorConfiguration } from 'src/Redux/Configurations/actions/connectorActions';
import { getCurrentBot } from 'src/Redux/Bot/selectors';
import { getLoadingStatuses } from 'src/Redux/Loading/selectors';
import { useTranslation } from 'react-i18next';

type ModalMode = 'new' | 'import' | 'replace' | null;

type FileType = File | null;
interface CreateConfigContainerProps {
  type?: string;
  config?: Models.WebsiteConfiguration;
  forcedMode?: ModalMode;
}

export interface CreateConfigProps extends CreateConfigContainerProps {
  createConfig: () => void;
  type?: string;
  config?: Models.WebsiteConfiguration;
  actions: ActionProps[];
  createType: ModalMode;
  setCreateType: (type: ModalMode) => void;
  configName: string;
  setConfigName: (name: string) => void;
  fileRef: any;
  configFile: FileType;
  setConfigFile: (file: FileType) => void;
  selectFile: () => void;
}

const CreateConfigContainer = ({ type, config, forcedMode = null }: CreateConfigContainerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userName = useSelector(getUserName);
  const userLang = useSelector(getUserLang);
  const currentBot = useSelector(getCurrentBot);
  const fileRef = useRef<any>(null);
  const [createType, setCreateType] = useState<ModalMode>(forcedMode);
  const [configName, setConfigName] = useState<string>(config?.name || '');
  const [configFile, setConfigFile] = useState<FileType>(null);

  const isLoading = useSelector((state) =>
    getLoadingStatuses(state, [
      WEBSITE_CONFIGURATION.IMPORT_ONE.REQUEST,
      WEBSITE_CONFIGURATION.CREATE_ONE.REQUEST,
      CONNECTOR_CONFIGURATION.CREATE_ONE.REQUEST,
    ])
  );

  const handleCancel = () => {
    setCreateType(null);
    setConfigFile(null);
    setConfigName('');
    fileRef.current.value = '';
  };

  const handleCreateConfig = () => {
    if (currentBot?.botUUID && type && userName) {
      const data = {
        name: configName,
        type: type,
        language: userLang,
        botUUID: currentBot?.botUUID,
        modifiedBy: userName || '',
      };

      switch (data.type) {
        case CONFIG_TYPES.WEBSITE:
        case CONFIG_TYPES.CUSTOMER:
          return dispatch(createWebsiteConfiguration.request(data));
        case CONFIG_TYPES.META:
        case CONFIG_TYPES.TEAMS:
          return dispatch(createConnectorConfiguration.request(data));
        default:
          throw 'Config type not yet suported';
      }
    }
  };

  const handleConfigImport = () =>
    type &&
    configFile &&
    configName &&
    dispatch(importWebsiteConfiguration.request(type, configName, configFile, config?.id));

  const hanleSelectFile = () => fileRef.current.click();

  const hanleFileChange = (e) => {
    setConfigFile(e.target.files[0]);
    setCreateType(createType || 'import');
  };

  const actions: ActionProps[] = [
    {
      label: t('general.previous'),
      className: 'p-button-text p-button-secondary',
      icon: 'icon-caret-left',
      onClick: handleCancel,
      hidden: forcedMode !== null,
    },
    {
      label: t('general.create'),
      icon: 'icon-check',
      hidden: createType !== 'new',
      disabled: configName.length < 1 || isLoading,
      onClick: () => handleCreateConfig(),
    },
    {
      label: t('general.import'),
      icon: 'icon-check',
      hidden: createType === 'new',
      disabled: !configFile || configName.length < 1,
      loading: isLoading,
      onClick: () => handleConfigImport(),
    },
  ];

  const props: CreateConfigProps = {
    createConfig: handleCreateConfig,
    type,
    actions,
    createType,
    setCreateType,
    configName,
    setConfigName,
    forcedMode,
    fileRef,
    configFile,
    setConfigFile,
    config,
    selectFile: hanleSelectFile,
  };

  return (
    <MainContainer>
      {createType || (createType && ['import', 'replace'].includes(createType) && configFile) ? (
        <CreateForm {...props} />
      ) : (
        <CreateType {...props} />
      )}
      <input className='file-input' ref={fileRef} type='file' accept={UPLOAD_TYPE.ZIP} onChange={hanleFileChange} />
    </MainContainer>
  );
};

export default CreateConfigContainer;
