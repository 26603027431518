import jsonPatch from 'fast-json-patch';
import { BrokenRelease, Release } from '../../../typings/states';
import {
  findContentJsonBrokenRelease,
  findContentJsonRelease,
  findThemeJsonRelease,
  findTranslationJsonBrokenRelease,
  findTranslationJsonRelease,
} from './versions/release-versions';
import latest from './versions/current/current';

const updateJsonToLatest = (jsonToUpdate: any, currentRelease: Release, getPatchToNextVersion) => {
  if (currentRelease?.nextRelease) {
    const patchToNextVersion = getPatchToNextVersion(currentRelease);

    let updatedContent;
    if (patchToNextVersion) {
      updatedContent = jsonPatch.applyPatch(jsonToUpdate, patchToNextVersion).newDocument;
    } else {
      updatedContent = jsonToUpdate;
    }

    return updateJsonToLatest(updatedContent, currentRelease.nextRelease, getPatchToNextVersion);
  }

  return jsonToUpdate;
};

const repairJson = (jsonToRepair: any, brokenRelease: BrokenRelease | undefined, getRepairPatch) => {
  if (brokenRelease) {
    console.log('Broken release found ! version=' + brokenRelease.version);
    const repairPatch = getRepairPatch(brokenRelease);

    if (repairPatch) {
      return jsonPatch.applyPatch(jsonToRepair, repairPatch).newDocument;
    }
  }

  return jsonToRepair;
};

const Releases = {
  updateContentToLatest: (contentToUpdate: any) => {
    let jsonRelease = findContentJsonRelease(contentToUpdate);

    if (!jsonRelease) {
      console.log('No content release found, trying to repair...');
      const brokenRelease = findContentJsonBrokenRelease(contentToUpdate);
      contentToUpdate = repairJson(
        contentToUpdate,
        brokenRelease,
        (brokenRelease: BrokenRelease) => brokenRelease.repairContentPatch
      );
      jsonRelease = findContentJsonRelease(contentToUpdate);
    }

    console.log('config version=' + jsonRelease?.version);
    if (jsonRelease) {
      return updateJsonToLatest(contentToUpdate, jsonRelease, (release: Release) => release.contentPatchToNextVersion);
    }

    return JSON.parse(JSON.stringify(latest.content)); // Create deep copy of content
  },
  updateTranslationToLatest: (translationToUpdate: any) => {
    let jsonRelease = findTranslationJsonRelease(translationToUpdate);

    if (!jsonRelease) {
      console.log('No translation release found, trying to repair...');
      const brokenRelease = findTranslationJsonBrokenRelease(translationToUpdate);
      translationToUpdate = repairJson(
        translationToUpdate,
        brokenRelease,
        (brokenRelease: BrokenRelease) => brokenRelease.repairTranslationPatch
      );
      jsonRelease = findTranslationJsonRelease(translationToUpdate);
    }

    console.log('Translation version=' + jsonRelease?.version);
    if (jsonRelease) {
      return updateJsonToLatest(
        translationToUpdate,
        jsonRelease,
        (release: Release) => release.translationPatchToNextVersion
      );
    }

    return JSON.parse(JSON.stringify(latest.translation)); // Create deep copy of translation
  },
  updateThemeToLatest: (themeToUpdate: any) => {
    const jsonRelease = findThemeJsonRelease(themeToUpdate);

    console.log('Theme version=' + jsonRelease?.version);
    if (jsonRelease) {
      return updateJsonToLatest(themeToUpdate, jsonRelease, (release: Release) => release.themePatchToNextVersion);
    }

    return JSON.parse(JSON.stringify(latest.theme)); // Create deep copy of theme
  },
};

export default Releases;
