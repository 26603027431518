import { Column, DataTable as PrimeDataTable } from './Style';

import { CSSProperties } from 'styled-components';

interface ColumnProps {
  id: string;
  title: string;
  style?: CSSProperties;
  body?: any;
}

interface DataTableProps {
  data: any;
  rowClassName: any;
  header?: any;
  columns: ColumnProps[];
  paginatorTemplate?: string;
  rows?: number;
  onRowClick?: (e) => void;
  paginator?: boolean;
  rowsPerPage?: boolean;
  rowsPerPageOptions?: number[];
}

const defaultRowsPerPageOptions = [];

const DataTable = ({
  data,
  header,
  columns,
  onRowClick,
  rowClassName,
  paginatorTemplate = 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
  rows = 10,
  rowsPerPage = false,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  paginator,
}: DataTableProps) => {
  const renderColumns = () =>
    columns?.map((column) => (
      <Column key={column.id} field={column.id} header={column.title} body={column.body} style={column?.style || {}} />
    ));

  return (
    <PrimeDataTable
      value={data}
      paginator={paginator || data?.length > rows}
      header={header}
      rowClassName={rowClassName}
      paginatorTemplate={paginatorTemplate}
      rows={(rowsPerPage && rowsPerPageOptions[0]) || rows}
      rowsPerPageOptions={rowsPerPageOptions}
      onRowClick={onRowClick}
    >
      {renderColumns()}
    </PrimeDataTable>
  );
};

export default DataTable;
