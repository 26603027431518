import styled, { css } from 'styled-components';

export const ImageWrapper = styled.div`
  & + * {
    margin-left: 10px;
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

export const Image = styled.img`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
`;
