import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;

  > button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
