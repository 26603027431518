import { useCallBotConfigInfo } from '../../../../Contexts/CallBotContext';
import { Panel } from 'primereact/panel';
import { CallBotWrapper } from '../../Style';
import ImageVoxygen from '../../../../Assets/Images/logo-voxygen.png';
import ImageElevenLabs from '../../../../Assets/Images/logo-elevenlabs.png';
import ImageMicrosoft from '../../../../Assets/Images/logo-microsoft.jpeg';
import ImageGoogle from '../../../../Assets/Images/logo-google.png';
import ImageDydu from '../../../../Assets/Images/logo-dydu.png';
import { useTranslation } from 'react-i18next';

const CallBotContainer = () => {
  const { selectedCallBotConfig } = useCallBotConfigInfo();
  const { t } = useTranslation();

  const ImageByTts = (ttsProvider: string) => {
    switch (ttsProvider) {
      case 'VOXYGEN':
        return ImageVoxygen;
      case 'ELEVENLABS':
        return ImageElevenLabs;
      default:
        return '';
    }
  };

  const ImageByStt = (sttProvider: string) => {
    switch (sttProvider) {
      case 'GOOGLE':
        return ImageGoogle;
      case 'MICROSOFT':
        return ImageMicrosoft;
      case 'DYDU_WHISPER':
        return ImageDydu;
      default:
        return '';
    }
  };

  return (
    <CallBotWrapper>
      <Panel header={t('edition.callbot.phone')} toggleable key={t('edition.callbot.phone')}>
        <p>{(selectedCallBotConfig?.phoneLine.match(/.{1,2}/g) || []).join(' ')}</p>
      </Panel>
      <Panel header={t('edition.callbot.TTS')} toggleable key={t('edition.callbot.TTS')}>
        <p>{selectedCallBotConfig?.tts?.provider}</p>
        <img src={ImageByTts(selectedCallBotConfig?.tts?.provider)} alt={selectedCallBotConfig?.tts?.provider} />
      </Panel>
      <Panel header={t('edition.callbot.STT')} toggleable className='stt-provider' key={t('edition.callbot.STT')}>
        {selectedCallBotConfig?.stt?.enabledModels.map((model) => (
          <div className='stt-wrapper'>
            <p key={model}>{model}</p>
            <img src={ImageByStt(model)} alt={model} />
          </div>
        ))}
      </Panel>
    </CallBotWrapper>
  );
};

export default CallBotContainer;
