import { all, call, fork, put, spawn } from 'redux-saga/effects';
import { appInit } from './App/actions';

import appSaga from './App/sagas';
import botSaga from './Bot/sagas';
import configurationsSaga from './Configurations/sagas';

// WATCHERS
export function* startWatchers() {
  try {
    yield all([spawn(appSaga), spawn(botSaga), spawn(configurationsSaga)]);
  } catch (e: any) {
    yield put(appInit.failure(e));
  }
}

/**
 * ROOT SAGA
 */
export function* root() {
  try {
    yield call(startWatchers);
    yield put(appInit.success());
  } catch (error: any) {
    yield put(appInit.failure(error));
  }
}

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSagas() {
  try {
    yield put(appInit.request());
    yield fork(root);
  } catch (error: any) {
    yield put(appInit.failure(error));
  }
}
