import Tag, { SeverityType } from 'src/Components/Tag/Tag';

import { InfoTooltipWrapper } from '../Style';
import { useTranslation } from 'react-i18next';
import { getLastPublicationOfType } from '../../../Tools/DyduboxConfigurationUtils';

type publicationProps = {
  publisher?: string;
  publishedAt?: string;
  env: string;
  severity: SeverityType;
};

type publicationListProps = publicationProps[];

interface InfoTooltipProps {
  config: Models.WebsiteConfiguration;
  formatDateUseLocale(date: string | number): string;
}

const InfoTooltip = ({ config, formatDateUseLocale }: InfoTooltipProps) => {
  const { t } = useTranslation();

  const lastPreprodPublication = getLastPublicationOfType(config?.publications, 'PREPROD');
  const lastProdPublication = getLastPublicationOfType(config?.publications, 'PROD');

  const publicationList: publicationListProps = [
    {
      publisher: lastPreprodPublication?.publisher,
      publishedAt: lastPreprodPublication ? formatDateUseLocale(lastPreprodPublication.publishedAt) : '',
      env: t('general.publish_status.preprod'),
      severity: 'warning',
    },
    {
      publisher: lastProdPublication?.publisher,
      publishedAt: lastProdPublication ? formatDateUseLocale(lastProdPublication.publishedAt) : '',
      env: t('general.publish_status.prod'),
      severity: 'success',
    },
  ];

  const renderPublication = (data: publicationProps) =>
    data?.publishedAt && (
      <div key={data.env} className='publication'>
        <Tag value={data.env} severity={data.severity} />
        <div className='publisher'>
          <span className='bg-grey'>par </span> <span>{data.publisher}</span>
        </div>
        <div className='date'>
          <span className='bg-grey'>le </span> <span>{data.publishedAt}</span>
        </div>
      </div>
    );

  const renderPublicationList = () => publicationList?.map((publication) => renderPublication(publication));

  return <InfoTooltipWrapper>{renderPublicationList()}</InfoTooltipWrapper>;
};

export default InfoTooltip;
