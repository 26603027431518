import Button, { ButtonProps } from 'src/Components/Button/Button';

import { MainContainer } from './Style';

export interface ActionProps extends ButtonProps {}
interface ActionsProps {
  actions: ActionProps[];
  className?: string;
}

const Actions = ({ actions = [], className = '' }: ActionsProps) => (
  <MainContainer className={`actions-wrapper ${className}`}>
    {actions.map((props: ActionProps, index) => !props.hidden && <Button {...props} key={index} />)}
  </MainContainer>
);

export default Actions;
