import { ColumnWrapper, Container, EditionWrapper } from 'src/Pages/Edition/Style';

import Application from './Configuration/Application';
import General from './Configuration/General';
import Heading from 'src/Components/Heading/Heading';
import Identity from './Configuration/Identity';
import Links from './Configuration/Links';
import { TeamsStepProps } from 'src/Pages/Edition/Containers/Connector/Teams/TeamsContainer';
import { useTranslation } from 'react-i18next';
import Options from './Configuration/Options';

export interface ConfigurationProps extends TeamsStepProps {}

const Configuration = (props: ConfigurationProps) => {
  const { t } = useTranslation();

  return (
    <EditionWrapper className='connector-configuration'>
      {/** General Information */}
      <Heading as='h4' title={t('edition.generalInfo.title')} />
      <General {...props} />

      <ColumnWrapper>
        <Container>
          {/** Application */}
          <Heading as='h4' title={t('edition.application.title')} />
          <Application {...props} />

          <Heading as='h4' title={t('edition.options.title')} />
          <Options {...props} />
        </Container>
        <Container>
          {/** Identity */}
          <Heading as='h4' title={t('edition.identity.title')} />
          <Identity {...props} />

          {/** Links */}
          <Heading as='h4' title={t('edition.links.title')} />
          <Links {...props} />
        </Container>
      </ColumnWrapper>
    </EditionWrapper>
  );
};

export default Configuration;
