import translation from './translation.json';
import translationPatchToNextVersion from './translation-patch-to-next-version.json';
import translationSchema from './translation-schema.json';
import { Release } from '../../../../../typings/states';
import nextRelease from '../chatbox_edge_2023-08-08/chatbox_edge_2023-08-08';

const release: Release = {
  version: 'chatbox_edge_2023-07-25',
  translation: translation,
  translationSchema: translationSchema,
  translationPatchToNextVersion: translationPatchToNextVersion,
  nextRelease: nextRelease,
};

export default release;
