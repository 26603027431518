const ResetCss = `
    #dydu-root .dydu-application .dydu-chatbox{
        right: 0px;
        bottom: 0px;
        width: 100% !important;
        height: 100% !important;
        max-height: inherit !important;
    }
`;

export default ResetCss;
