import Ajv from 'ajv';
import { InnerContent } from 'src/Pages/Edition/Style';
import { JsonEditor } from 'jsoneditor-react';
import Message from 'src/Components/Message/Message';
import { WebsiteStepProps } from 'src/Pages/Edition/Containers/Webiste/WebsiteContainer';
import addFormats from 'ajv-formats';
import toJsonSchema from 'to-json-schema';
import { useTranslation } from 'react-i18next';

export interface DebugProps extends WebsiteStepProps {}

const Debug = ({ selectedConfig, watch, setValue }: DebugProps) => {
  const { t } = useTranslation();
  const ajv = new Ajv({
    allErrors: true,
    verbose: true,
  });
  ajv.addFormat(
    'color',
    /^(#?([0-9A-Fa-f]{3}){1,2}\b|aqua|black|blue|fuchsia|gray|green|lime|maroon|navy|olive|orange|purple|red|silver|teal|white|yellow|(rgb\(\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*,\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*,\s*\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\b\s*\))|(rgb\(\s*(\d?\d%|100%)+\s*,\s*(\d?\d%|100%)+\s*,\s*(\d?\d%|100%)+\s*\)))$/
  );
  addFormats(ajv);

  const validationSchema = toJsonSchema(selectedConfig.content);

  const handleOnChange = (json: Models.WebsiteConfiguration) => setValue('content', json);

  const handleOnError = (error) => console.log('error', error);

  return (
    <InnerContent className='json-editor'>
      <Message severity='warn' text={t('edition.debug.warning')} wide />
      <JsonEditor
        value={watch('content')}
        onChange={handleOnChange}
        onError={handleOnError}
        schema={validationSchema}
        ajv={ajv}
        history={true}
        statusBar={false}
        mode='form'
      />
    </InnerContent>
  );
};

export default Debug;
