import { CONFIG_TYPES } from 'src/Tools/Constants';
import { ConfigType } from '../Style';
import { CreateConfigProps } from '../CreateConfigContainer';
import Icon from 'src/Components/Icon/Icon';
import { useTranslation } from 'react-i18next';

const CreateType = ({ setCreateType, type, selectFile }: CreateConfigProps) => {
  const { t } = useTranslation();

  return (
    <ConfigType>
      {type !== CONFIG_TYPES.CUSTOMER && (
        <div className='type' onClick={() => setCreateType('new')}>
          <div>
            <Icon icon='add' size={35} color='#7091D8' />
          </div>
          <div>{t('newConfig.modal.create_type.add')}</div>
          <div>
            <Icon icon='arrow-right' />
          </div>
        </div>
      )}
      {type === CONFIG_TYPES.WEBSITE && (
        <div className='type' onClick={selectFile}>
          <div>
            <Icon icon='upload' size={35} color='#7091D8' />
          </div>
          <div>{t('newConfig.modal.create_type.import')}</div>
          <div>
            <Icon icon='arrow-right' />
          </div>
        </div>
      )}
    </ConfigType>
  );
};

export default CreateType;
