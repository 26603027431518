import ControlledSwitch from 'src/Components/Switch/ControlledSwitch';
import { DisplayProps } from '../Display';
import { Divider } from 'src/Components/Divider/Style';
import { InnerContent } from 'src/Pages/Edition/Style';
import { useTranslation } from 'react-i18next';

interface SurveyProps extends DisplayProps {}

const Survey = ({ control }: SurveyProps) => {
  const { t } = useTranslation();

  return (
    <InnerContent className='survey'>
      <ControlledSwitch
        control={control}
        label={t('edition.advanced.field.askChoice.label')}
        subtitle={t('edition.advanced.field.askChoice.subtitle')}
        icon='thumb-down'
        name='content.feedback.askChoices'
      />
      <Divider />
      <ControlledSwitch
        control={control}
        label={t('edition.advanced.field.askComment.label')}
        subtitle={t('edition.advanced.field.askComment.subtitle')}
        icon='chat-bubble'
        name='content.feedback.askComment'
      />
    </InnerContent>
  );
};

export default Survey;
