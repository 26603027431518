import { APP_PATH, CONFIG_TYPES } from 'src/Tools/Constants';
import { getAllConfigurations, setSelectedConfiguration } from 'src/Redux/Configurations/actions/configurationsActions';
import { useDispatch, useSelector } from 'react-redux';

import Blank from 'src/Pages/Blank/Blank';
import { CONFIGURATIONS } from 'src/Redux/Configurations/types';
import Configurations from '../Components/Configurations';
import CreateConfigContainer from 'src/Modals/CreateConfig/CreateConfigContainer';
import EmptyList from '../Components/EmptyList';
import { getConfigurationsList } from 'src/Redux/Configurations/selectors';
import { getCurrentBot } from 'src/Redux/Bot/selectors';
import { getLoadingStatus } from 'src/Redux/Loading/selectors';
import { getUserLang } from 'src/Redux/User/selectors';
import { openModal } from 'src/Redux/Modal/actions';
import useDate from 'src/Hooks/useDate';
import useDocumentTitle from 'src/Hooks/useDocumentTitle';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCallBotConfigInfo } from '../../../Contexts/CallBotContext';

export interface ConfigurationsContainerProps {
  configurationsList: Models.ConfigTypes[];
  importConfig: () => void;
  openConfig(id: string, type: string): void;
  formatDateUseLocale(lang): any;
}

const ConfigurationsContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useDocumentTitle(`${t('general.pages.main')} - ${t('general.pages.configurations')}`);
  const { setSelectedCallBotConfig, callBotConfigurations } = useCallBotConfigInfo();

  const userlang = useSelector(getUserLang);
  const currentBot = useSelector(getCurrentBot);
  const configurationsList = useSelector(getConfigurationsList);
  const configurationsLoading = useSelector((state) => getLoadingStatus(state, CONFIGURATIONS.GET_ALL.REQUEST));

  const { formatDateUseLocale } = useDate(userlang);

  useEffect(() => {
    dispatch(setSelectedConfiguration(null));
  }, []);

  useEffect(() => {
    if (currentBot?.botUUID) {
      dispatch(getAllConfigurations.request(currentBot));
    }
  }, [currentBot?.botUUID]);

  const handleOpenConfig = (id: string, type: string) => {
    if (id && type) {
      if (type === CONFIG_TYPES.CALLBOT) {
        setSelectedCallBotConfig(callBotConfigurations.find((config: any) => config.uuid === id));
      }
      navigate(`${APP_PATH.CONFIGURATIONS}/${type}/${id}`);
    }
  };

  const handleImportConfig = () =>
    dispatch(
      openModal({
        body: <CreateConfigContainer forcedMode='import' type={CONFIG_TYPES.CUSTOMER} />,
        title: t('configurations.import_config'),
      })
    );

  const props: ConfigurationsContainerProps = {
    importConfig: handleImportConfig,
    openConfig: handleOpenConfig,
    configurationsList,
    formatDateUseLocale,
  };

  if (configurationsLoading !== false && configurationsList?.length === 0) {
    return <Blank />;
  }

  return configurationsList?.length > 0 ? <Configurations {...props} /> : <EmptyList {...props} />;
};

export default ConfigurationsContainer;
