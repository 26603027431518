import styled, { css } from 'styled-components';

export const CenteredContent = styled.div`
  margin: 0 auto;
  min-width: 500px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainInputWrapper = styled.div`
  margin: 0 10px 10px 0;
  display: flex;
`;

export const MainLabel = styled.div`
  .title {
    font-size: 16px;
    color: ${({ theme }) => theme.color_grey__600};
  }
  .subtitle {
    font-size: 12px;
    color: ${({ theme }) => theme.color_grey__500};
  }

  .title,
  .subtitle {
    padding: 0 40px 0 0;
    font-weight: 500;
  }
  .pi {
    margin: 2px 10px 0 0;
    font-size: 16px;
  }
`;

export const SubField = styled.div`
  margin: 0 10px 10px 0;
  display: flex;
  border-radius: 6px;
  background: #f2f4fb;
  border: 1px solid ${({ theme }) => theme.color_grey__400};
  padding: 15px;
  flex-direction: ${({ direction = 'row' }: { direction?: 'row' | 'column' }) => direction};

  ${({ hidden }: { hidden?: boolean }) =>
    hidden &&
    css`
      display: none;
    `}
`;
