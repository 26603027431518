import { ColumnWrapper, EditionWrapper } from 'src/Pages/Edition/Style';

import AppCredentialsContainer from 'src/Pages/Edition/Containers/Connector/Meta/Modals/AppCredentialsContainer';
import Dashboard from './Pages/Dashboard';
import { MetaStepProps } from 'src/Pages/Edition/Containers/Connector/Meta/MetaContainer';
import Sidebar from './Pages/Sidebar';
import { getSelectedMetaLinkedObjects } from 'src/Redux/Configurations/selectors';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export interface PagesProps extends MetaStepProps {
  waitingForCredentials?: boolean;
  linkedObjects: API_CONNECTOR.Schemas.MetaLinkedObjectDTO[];
  editCredentials: (closable?: boolean, edit?: boolean) => void;
}

const Pages = (props: MetaStepProps) => {
  const { t } = useTranslation();
  const linkedObjects = useSelector(getSelectedMetaLinkedObjects);
  const { selectedConfig, openModal, closeModal, setCurrentStep, guideUrl } = props;
  const waitingForCredentials = !selectedConfig?.info?.clientId && !selectedConfig?.info?.clientSecret;

  const handleSetPrevious = () => {
    setCurrentStep('previous');
    closeModal();
  };

  const handleEditCredentials = (closable, edit) =>
    openModal(
      t('edition.meta.modal.appCredentials.title'),
      <AppCredentialsContainer edit={edit} onCancel={handleSetPrevious} guideUrl={guideUrl} />,
      {
        closable,
      }
    );

  useEffect(() => {
    if (waitingForCredentials) {
      handleEditCredentials(false, false);
    }
  }, [waitingForCredentials]);

  const pagesProps: PagesProps = {
    ...props,
    linkedObjects,
    editCredentials: handleEditCredentials,
    waitingForCredentials,
  };

  return (
    <EditionWrapper className='connector-pages'>
      <ColumnWrapper className={`inner-content ${selectedConfig.type}`}>
        <Sidebar {...pagesProps} />
        <Dashboard {...pagesProps} />
      </ColumnWrapper>
    </EditionWrapper>
  );
};

export default Pages;
