import { closeModal, openModal } from 'src/Redux/Modal/actions';
import { useDispatch, useSelector } from 'react-redux';

import { ActionProps } from 'src/Components/Actions/Actions';
import { CONNECTOR_CONFIGURATION } from 'src/Redux/Configurations/types';
import ConnectorActions from '../Components/ConnectorActions';
import { ReactNode } from 'react';
import { deleteConnectorConfiguration } from 'src/Redux/Configurations/actions/connectorActions';
import { getLoadingStatus } from 'src/Redux/Loading/selectors';
import { useTranslation } from 'react-i18next';

interface ConnectorActionsContainerProps {
  config: Models.ConnectorConfiguration;
  disabled?: boolean;
}
export interface ConnectorActionsProps {
  config: Models.ConnectorConfiguration;
  deleteModalActions: any[];
  openModal: (title: string, body: ReactNode, actions?: any[]) => void;
}

const ConnectorActionsContainer = ({ config }: ConnectorActionsContainerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => getLoadingStatus(state, CONNECTOR_CONFIGURATION.DELETE_ONE.REQUEST));

  const handleOpenModal = (title: string, body: any, actions?: any[]) => dispatch(openModal({ title, body, actions }));

  const handleDeleteConfig = () =>
    config.id && config?.type && dispatch(deleteConnectorConfiguration.request(config.id, config.type));

  const deleteModalActions: ActionProps[] = [
    {
      label: t('general.cancel'),
      className: 'p-button-text p-button-secondary',
      icon: 'icon-close',
      onClick: () => dispatch(closeModal()),
    },
    {
      label: t('general.delete'),
      icon: 'icon-trash',
      onClick: handleDeleteConfig,
      loading: isLoading,
      className: 'p-button-danger',
    },
  ];

  const props: ConnectorActionsProps = {
    config,
    deleteModalActions,
    openModal: handleOpenModal,
  };

  return <ConnectorActions {...props} />;
};

export default ConnectorActionsContainer;
