import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  header {
    img {
      width: 12.5rem;
      cursor: pointer;
    }
  }
  .card {
    max-width: 46.4rem;
    margin: 0 auto;
    padding: 3.5rem;
    background-color: $color_white;
    border-radius: $border-radius_12;
    box-shadow: 0 5px 72px 0 rgba(65, 74, 85, 0.1);
    button {
      border-radius: 6px;
    }
  }
`;
