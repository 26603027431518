import Actions from 'src/Components/Actions/Actions';
import DocLink from './DocLink';
import Input from 'src/Components/Input/Input';
import { LinkedObjectProps } from 'src/Pages/Edition/Containers/Connector/Meta/Modals/LinkedObjectContainer';
import { ModalWrapper } from './Style';
import { useTranslation } from 'react-i18next';

const LinkedObject = ({
  data,
  accessToken,
  objectId,
  setObjectId,
  setAccessToken,
  actions,
  guideUrl,
  type,
}: LinkedObjectProps) => {
  const { t } = useTranslation();
  const objectIdLabel =
    type === 'whatsapp'
      ? t('edition.meta.modal.appCredentials.phoneNumberId')
      : t('edition.meta.modal.appCredentials.objectId');

  return (
    <ModalWrapper>
      <DocLink link={guideUrl} />
      <Input
        value={objectId}
        onChange={(e) => setObjectId(e.target.value)}
        placeholder={objectIdLabel}
        label={objectIdLabel}
        disabled={data?.name !== undefined}
      />
      <Input
        value={accessToken}
        onChange={(e) => setAccessToken(e.target.value)}
        placeholder={t('edition.meta.modal.appCredentials.accessToken')}
        label={t('edition.meta.modal.appCredentials.accessToken')}
      />
      <Actions actions={actions} />
    </ModalWrapper>
  );
};

export default LinkedObject;
