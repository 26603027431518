import { WEBSITE_CONFIGURATION } from 'src/Redux/Configurations/types';
import { useDispatch, useSelector } from 'react-redux';

import { ActionProps } from 'src/Components/Actions/Actions';
import { MainContainer } from './Style';
import RestorePublication from './RestorePublication';
import { closeModal } from 'src/Redux/Modal/actions';
import { getLoadingStatuses } from 'src/Redux/Loading/selectors';
import { restorePublishedConfiguration } from 'src/Redux/Configurations/actions/websiteActions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLastPublicationOfType } from '../../Tools/DyduboxConfigurationUtils';

interface RestorePublicationContainerProps {
  configId: string;
  publications: API_BACKEND.Schemas.ConfigurationPublicationDTO[];
}

export interface RestorePublicationProps {
  actions: ActionProps[];
  publications: API_BACKEND.Schemas.ConfigurationPublicationDTO[];
  publicationToRestore?: string;
  setPublicationToRestore: (name: string) => void;
  handleRestorePublication: () => void;
}

const RestorePublicationContainer = ({ configId, publications }: RestorePublicationContainerProps) => {
  const currentPublication = getLastPublicationOfType(publications, 'PROD');

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [publicationToRestore, setPublicationToRestore] = useState(currentPublication?.id);

  const loading = useSelector((state) => getLoadingStatuses(state, [WEBSITE_CONFIGURATION.RESTORE_ONE.REQUEST]));

  const handleRestorePublication = () => {
    if (configId && publicationToRestore) {
      dispatch(restorePublishedConfiguration.request(configId, publicationToRestore));
    }
  };

  const actions: ActionProps[] = [
    {
      label: t('general.cancel'),
      className: 'p-button-text p-button-secondary',
      icon: 'icon-close',
      onClick: () => dispatch(closeModal()),
    },
    {
      label: t('edition.restore.action.restore'),
      loading,
      icon: 'icon-history',
      disabled: !publicationToRestore || publicationToRestore === currentPublication?.id,
      onClick: handleRestorePublication,
    },
  ];

  const props: RestorePublicationProps = {
    actions,
    publications: publications?.filter(
      (publication) => publication.id !== currentPublication?.id && publication.storageUUID
    ),
    publicationToRestore,
    setPublicationToRestore,
    handleRestorePublication: handleRestorePublication,
  };

  return (
    <MainContainer>
      <RestorePublication {...props} />
    </MainContainer>
  );
};

export default RestorePublicationContainer;
