import { CustomLoader, LoaderContainer } from './Style';

import { getLoadingStatuses } from 'src/Redux/Loading/selectors';
import { useSelector } from 'react-redux';

interface LoaderProps {
  actions?: string[];
  loading?: boolean;
}

const Loader = ({ actions = [], loading = undefined }: LoaderProps) => {
  const loadingStatuses = useSelector((state) => getLoadingStatuses(state, actions));
  const isLoading = loading || loadingStatuses;

  return isLoading ? (
    <LoaderContainer>
      <CustomLoader color='#3636B9' loading={isLoading} size={50} />
    </LoaderContainer>
  ) : (
    <></>
  );
};

export default Loader;
