import { Button as PrimeButton } from 'primereact/button';
import styled from 'styled-components';

export const Button = styled(PrimeButton)`
  .p-button-icon,
  .p-button-label {
    font-size: 14px;
    white-space: nowrap;
    font-weight: 500;

    &::before {
      color: inherit !important;
    }
  }
`;
