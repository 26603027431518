import Button, { ButtonProps } from '../Button/Button';
import { ButtonsWrapper, TranslationUploadWrapper } from './Style';
import { useRef, useState } from 'react';

import Image from '../Image/Image';
import SplitButton from '../SplitButton/SplitButton';
import { getLanguages } from 'src/Redux/Bot/selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface TranslationUploadProps extends ButtonProps {
  onUpload?: any;
  onClear?: any;
  file?: File | null;
  accept?: string;
  loading?: boolean;
}

const TranslationUpload = ({
  icon,
  label,
  accept,
  file = null,
  className = '',
  loading,
  onUpload,
  onClear,
}: TranslationUploadProps) => {
  const { t } = useTranslation();
  const languages = useSelector(getLanguages);
  const inputFile = useRef<any>(null);
  const [currentFile, setCurrentFile] = useState<any>(file);
  const [selectedLang, setSelectedLang] = useState<string | null>(null);

  const props = {
    icon,
    label: currentFile?.name || label,
    className,
    disabled: loading,
    loading,
    onClick: () => inputFile.current.click(),
  };

  const handleSelectFile = (lang: string | null) => {
    setSelectedLang(lang);
    inputFile.current.click();
  };

  const langItems = languages?.map((lang) => ({
    label: lang.name,
    command: () => handleSelectFile(lang.id),
    template: (item, options) => (
      <a className={options.className} onClick={options.onClick}>
        <Image src={`Icons/flags/${lang?.id}.png`} />
        <span className={options.labelClassName}>{item.label}</span>
      </a>
    ),
  }));

  const handleFileChange = (e) => setCurrentFile(e.target.files[0]);

  const handleCancel = () => {
    setCurrentFile(null);
    inputFile.current.value = '';
    onClear && onClear();
  };

  const handleValidate = async () => {
    onUpload && (await onUpload(currentFile, selectedLang));
    handleCancel();
  };

  const renderActions = () =>
    currentFile ? (
      <ButtonsWrapper>
        <Button {...props} icon='icon-excel' />
        {currentFile && (
          <Button onClick={handleCancel} label={t('general.cancel')} disabled={loading} icon='icon-close' />
        )}
        {currentFile && (
          <Button onClick={handleValidate} label={t('general.upload')} icon='icon-upload' loading={loading} />
        )}
      </ButtonsWrapper>
    ) : (
      <SplitButton
        className='translation-upload p-button-lg'
        onClick={() => handleSelectFile(null)}
        label={label}
        loading={loading}
        disabled={loading}
        model={langItems}
      />
    );

  return (
    <TranslationUploadWrapper>
      <input ref={inputFile} onChange={handleFileChange} type='file' accept={accept} />
      {renderActions()}
    </TranslationUploadWrapper>
  );
};

export default TranslationUpload;
