import { useDispatch, useSelector } from 'react-redux';

import { ActionProps } from 'src/Components/Actions/Actions';
import { MainContainer } from './Style';
import UpdateSlug from './UpdateSlug';
import { WEBSITE_CONFIGURATION } from 'src/Redux/Configurations/types';
import { closeModal } from 'src/Redux/Modal/actions';
import { getLoadingStatus } from 'src/Redux/Loading/selectors';
import { updateWebsiteConfigurationSlug } from 'src/Redux/Configurations/actions/websiteActions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface UpdateSlugContainerProps {
  config: Models.WebsiteConfiguration;
}

export interface UpdateSlugProps {
  actions: ActionProps[];
  configSlug: string;
  setConfigSlug: (slug: string) => void;
  updateConfigName: () => void;
}

const UpdateSlugContainer = ({ config }: UpdateSlugContainerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [configSlug, setConfigSlug] = useState(config.slug || '');
  const loading = useSelector((state) => getLoadingStatus(state, WEBSITE_CONFIGURATION.UPDATE_SLUG.REQUEST));

  const handleUpdateConfigName = () =>
    config?.id && dispatch(updateWebsiteConfigurationSlug.request(configSlug, config.id));

  const actions: ActionProps[] = [
    {
      label: t('general.cancel'),
      className: 'p-button-text p-button-secondary',
      icon: 'icon-close',
      onClick: () => dispatch(closeModal()),
    },
    {
      label: t('general.save'),
      loading,
      icon: 'icon-check',
      disabled: configSlug.length > 50,
      onClick: handleUpdateConfigName,
    },
  ];

  const props: UpdateSlugProps = {
    actions,
    configSlug,
    setConfigSlug,
    updateConfigName: handleUpdateConfigName,
  };

  return (
    <MainContainer>
      <UpdateSlug {...props} />
    </MainContainer>
  );
};

export default UpdateSlugContainer;
