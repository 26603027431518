import { Actions, HeaderMenu, HeaderWrapper } from './Style';

import { APP_URL } from 'src/Tools/Constants';
import BreadcrumbContainer from '../Breadcrumb/BreadcrumbContainer';
import Divider from '../Divider/Divider';
import { HeaderContainerProps } from './HeaderContainer';
import Icon from '../Icon/Icon';

const Header = ({
  currentBot,
  botsList,
  userlang,
  userName,
  botMenu,
  accountMenu,
  botListLoading,
}: HeaderContainerProps) => {
  const handleOpenInfo = () => window.open(APP_URL[userlang === 'fr' ? 'fr' : 'en'].doc, '_blank');

  const renderBotMenu = () =>
    !botListLoading && (
      <HeaderMenu
        model={botMenu}
        buttonIcon='icon-caret-down'
        buttonClassName='p-button-text p-button-sm inegration-menu'
        buttonLabel={currentBot?.name}
        buttonIconPos='right'
        popup
      />
    );

  const renderAccountMenu = () =>
    userName && (
      <HeaderMenu
        model={accountMenu}
        buttonIcon='icon-caret-down'
        buttonClassName='p-button-text p-button-sm inegration-menu'
        buttonLabel={userName}
        buttonIconPos='right'
        popup
      />
    );

  const renderActions = () => (
    <Actions>
      <Icon icon='info' color='#33333B' onClick={handleOpenInfo} />
      <Divider layout='vertical' />
      {renderBotMenu()}
      <Divider layout='vertical' />
      {renderAccountMenu()}
    </Actions>
  );

  if (!botsList && !currentBot) {
    return <></>;
  }

  return (
    <HeaderWrapper>
      <BreadcrumbContainer />
      {renderActions()}
    </HeaderWrapper>
  );
};

export default Header;
