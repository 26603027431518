import { GuideLinkButton } from './Style';
import Icon from 'src/Components/Icon/Icon';
import { useTranslation } from 'react-i18next';

interface DocLinkProps {
  link: string;
}

const DocLink = ({ link }: DocLinkProps) => {
  const { t } = useTranslation();
  return (
    <GuideLinkButton href={link} target='_blank'>
      <div className='title'>{t('edition.meta.modal.appGuide.title')}</div>
      <div className='subtitle'>{t('edition.meta.modal.appGuide.subtitle')}</div>
      <div className='caret'>
        <Icon icon='caret-right' color='white' />
      </div>
    </GuideLinkButton>
  );
};

export default DocLink;
