import Button, { ButtonProps } from '../Button/Button';
import { PublishButtonWrapper, VersionInput, VersionInputWrapper } from './Style';

import { CHATBOX_VERSION, CONFIG_TYPES } from 'src/Tools/Constants';
import { publishWebsiteConfiguration } from 'src/Redux/Configurations/actions/websiteActions';
import { useDispatch } from 'react-redux';
import useDydu from 'src/Hooks/useDydu';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface PublishButtonProps extends ButtonProps {
  id: string;
  env: string;
  configType: API_BACKEND.Schemas.ConfigurationType | undefined;
  onClick?: (version: string) => void;
}

const PublishButton = (props: PublishButtonProps) => {
  const { id, env, configType, onClick } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isDyduUser } = useDydu();
  const isNotCustomConfig = configType !== CONFIG_TYPES.CUSTOMER;

  const [chatboxVersion, setChatboxVersion] = useState(CHATBOX_VERSION);

  const handleOnClick = () =>
    onClick ? onClick(chatboxVersion) : dispatch(publishWebsiteConfiguration.request(id, env, chatboxVersion));

  const handleOnBlur = () => !chatboxVersion && setChatboxVersion(CHATBOX_VERSION);

  const handleSetChatboxVersion = (e) => setChatboxVersion(e.target.value);

  const buttonProps = {
    ...props,
    onClick: handleOnClick,
    disabled: !chatboxVersion,
  };

  const renderTargetInput = () =>
    isDyduUser && (
      <VersionInputWrapper>
        <VersionInput
          value={chatboxVersion}
          onChange={handleSetChatboxVersion}
          onBlur={handleOnBlur}
          label={t('edition.publish.action.targetVersion')}
        />
      </VersionInputWrapper>
    );

  return (
    <PublishButtonWrapper>
      <Button {...buttonProps} />
      {process.env.NODE_ENV === 'development' && isNotCustomConfig && renderTargetInput()}
    </PublishButtonWrapper>
  );
};

export default PublishButton;
