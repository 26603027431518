import * as Constants from 'src/Tools/Constants';
import * as Types from './types';

import produce from 'immer';
import { App } from '../../../typings/states';

export const initialState: App = {
  environment: '',
  status: null,
  devMode: false,
  lastUpdate: null,
};

const AppReducer = (state = initialState, { type, payload }: Redux.Action): App =>
  produce(state, (draft) => {
    switch (type) {
      case Types.SET_ENVIRONMENT:
        draft.environment = payload.env;
        break;
      case Types.APP.INIT.REQUEST:
        draft.status = Constants.APP_STATUS.REQUEST;
        break;
      case Types.APP.INIT.SUCCESS:
        draft.status = Constants.APP_STATUS.SUCCESS;
        break;
      case Types.APP.INIT.FAILURE:
        draft.status = Constants.APP_STATUS.FAILURE;
        break;
      default:
        return draft;
    }
  });

export default AppReducer;
