import * as Types from './types';

import { produce } from 'immer';
import { Bot } from '../../../typings/states';

const initialState: Bot = {
  list: [],
  current: null,
  consultationSpace: [],
  languages: [],
};

const BotReducer = (state = initialState, { type, payload }: Redux.Action): Bot =>
  produce(state, (draft) => {
    switch (type) {
      case Types.BOT.GET_ALL.SUCCESS:
        draft.list = payload.botsList;
        break;

      case Types.SET_CURRENT_BOT:
        draft.current = payload.data;
        break;

      case Types.BOT.CONSULTATION_SPACES.SUCCESS:
        draft.consultationSpace = payload.data;
        break;

      case Types.BOT.LANGUAGES.SUCCESS:
        draft.languages = payload.languages;
        break;

      default:
        return draft;
    }
  });

export default BotReducer;
