import { InputContent, InputSwitch, InputWrapper, Label } from './Style';

import Chip from 'src/Components/Chip/Chip';
import Icon from 'src/Components/Icon/Icon';
import { useRef } from 'react';

interface SwitchProps {
  onChange?: any;
  inputId?: string;
  value?: string;
  label?: string;
  subtitle?: string;
  icon?: string;
  image?: string;
  checked: boolean;
  disabled?: boolean;
  chip?: {
    label: string;
    hightlight?: string;
    hide?: boolean;
    onClick?: () => void;
  };
  className?: string;
}

const Switch = ({ label, subtitle, icon, image, className = '', chip, checked, onChange, disabled }: SwitchProps) => {
  const switchRef = useRef<any>(null);

  const switchProps = {
    label,
    subtitle,
    icon,
    className,
    chip,
    checked,
    onChange,
    disabled,
    ref: switchRef,
  };

  const handleOnChange = () => {
    !disabled && onChange();
  };

  const renderLabel = () =>
    label && (
      <Label onClick={handleOnChange}>
        {icon && <Icon icon={icon} />}
        {image && <img src={image} />}
        <div className='label'>
          <div className='title'>{label}</div>
          <div className='subtitle'>{subtitle}</div>
        </div>
      </Label>
    );

  const renderChip = () => chip && !chip?.hide && <Chip {...chip} />;

  const renderContent = () => (
    <InputContent>
      {renderChip()}
      <InputSwitch {...switchProps} />
    </InputContent>
  );

  return (
    <InputWrapper className={`switch-toggle ${className}`}>
      {renderLabel()}
      {renderContent()}
    </InputWrapper>
  );
};

export default Switch;
