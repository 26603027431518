import styled, { css } from 'styled-components';

export const ImageUploadWrapper = styled.div`
  input[type='file'] {
    display: none;
  }

  .message {
    margin: 5px 0 0 0;
  }

  .inner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100px;
    .actions {
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 992px) {
        flex-direction: column;
        button {
          margin-right: 0;
        }
      }
      .container-select-icon {
        position: relative;
        .caret-down {
          position: absolute;
          right: 8px;
          top: 12px;
        }
      }
      button {
        margin-right: 10px;
      }
    }

    .image.filter-hidden {
      position: relative;
      img {
        filter: blur(4px);
      }
      .icon-invisible {
        position: absolute;
        left: 23px;
        top: 50%;
      }
    }
  }

  .title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    padding: 5px;
    background-color: ${({ theme }) => theme.background_main_grey};
    justify-content: center;
    align-items: center;

    .image {
      width: 100px;
      height: 100px;
      justify-content: center;
      display: flex;
      align-items: center;
      padding: 10px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .actions {
      display: flex;
      flex: row;
      justify-content: space-around;

      .p-button {
        margin: 0;
      }
    }
  }

  ${({ size }: { size?: string }) =>
    size === 'medium' &&
    css`
      .inner-content {
        flex-direction: row;
        background-color: ${({ theme }) => theme.background_main_grey};
        border-radius: 6px;
        max-width: 300px;

        .image {
          border-radius: 6px;
          width: 200px;
          height: 130px;
          justify-content: center;
          display: flex;
          align-items: center;
          padding: 5px;
          background: #edf1f5;
          margin: 15px;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .content {
          width: 100%;
          display: flex;
          flex-direction: column;
          border-radius: 6px;
          padding: 5px;
          justify-content: center;
          align-items: flex-start;

          .actions {
            display: flex;
            flex-direction: column;

            .p-button {
              margin: 5px 0 0 5px;
            }
          }

          .title {
            font-size: 16px;
            margin-left: 10px;
          }
          .subtitle {
            font-size: 12px;
            margin-left: 10px;
          }
        }
      }
    `}

  ${({ size }: { size?: string }) =>
    size === 'large' &&
    css`
      width: 100%;

      .inner-content {
        flex-direction: row;
        background-color: ${({ theme }) => theme.background_main_grey};
        border: 1px solid ${({ theme }) => theme.color_grey__400};
        border-radius: 6px;
        padding: 10px;

        .image {
          max-width: 60px;
          min-width: 60px;
          margin: 0 10px;

          .upload {
            display: flex;
            justify-content: center;
          }
        }
        .image.hide {
          opacity: 0.4;
        }

        .action {
          margin-left: auto;
        }

        .content {
          background: none;
          display: inline-block;
          .title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0px;
          }
          .subtitle {
            display: inline-flex;
            margin-right: 0px;
            font-size: 12px;
            color: ${({ theme }) => theme.color_grey__500};
          }
        }
        .content.hide {
          opacity: 0.4;
        }
      }
    `}
`;

export const Select = styled.select`
  border: 1px solid #c3c8ce94;
  border-radius: 5px;
  background: white;
  padding: 8px;
  width: 105px;
`;
