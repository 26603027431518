import Input from '../Input/Input';
import styled from 'styled-components';

export const PublishButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VersionInputWrapper = styled.div`
  .input-wrapper {
    width: 100%;
    margin: 10px 0 0 0;
  }
`;

export const VersionInput = styled(Input)``;
