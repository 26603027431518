import ActionWrapper from 'src/Components/ActionWrapper/ActionWrapper';
import Chip from 'src/Components/Chip/Chip';
import { Divider } from 'src/Components/Divider/Style';
import { InformationsProps } from '../Informations';
import { InnerContent } from 'src/Pages/Edition/Style';
import { getCurrentBot } from 'src/Redux/Bot/selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface GeneralProps extends InformationsProps {}

const General = ({ watch }: GeneralProps) => {
  const { t } = useTranslation();

  const currentBot = useSelector(getCurrentBot);
  return (
    <InnerContent>
      <ActionWrapper title={t('edition.generalInfo.field.bot')} body={<Chip label={currentBot?.name} />} />
      <Divider />
      <ActionWrapper title={t('edition.generalInfo.field.server')} body={<Chip label={watch('server.hostname')} />} />
    </InnerContent>
  );
};

export default General;
