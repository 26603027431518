import { InnerContainer, MainContainer, OidcWarn, PreviewContainer } from './Style';
import { useEffect, useRef, useState } from 'react';

import ResetCss from './ResetCss';
import { WebsiteStepProps } from 'src/Pages/Edition/Containers/Webiste/WebsiteContainer';
import { getAppLastUpdate } from 'src/Redux/App/selectors';
import { getIframePreviewBundleUrl } from 'src/Tools/Url';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const WithPreview = (WrappedComponent: any) => {
  const ComponentWrapped = (props: WebsiteStepProps) => {
    const { t } = useTranslation();
    const iframeRef = useRef<any>(null);
    const appLastUpdate = useSelector(getAppLastUpdate);
    const [state, setstate] = useState('');
    const config = props.watch();

    useEffect(() => {
      setstate(
        `<script src='${getIframePreviewBundleUrl()}' ></script><script type="text/javascript" >var dyduReferer=true; DYDU_QUALIFICATION_MODE = true;</script>`
      );
    }, [config, appLastUpdate]);

    const handleAppendStyle = () => {
      const doc = iframeRef.current.contentDocument;
      doc.body.innerHTML = doc.body.innerHTML + `<style>${config.style}${ResetCss}</style>`;
    };

    const renderOidcWarn = () =>
      config?.content?.oidc?.enable && <OidcWarn wide severity='info' text={t('configurations.preview.oidc.warn')} />;

    return (
      <MainContainer>
        <InnerContainer>
          <WrappedComponent {...props} />
        </InnerContainer>
        <PreviewContainer>
          {renderOidcWarn()}
          <iframe id='cv5-iframe' srcDoc={state} ref={iframeRef} onLoad={handleAppendStyle} />
        </PreviewContainer>
      </MainContainer>
    );
  };

  return ComponentWrapped;
};

export default WithPreview;
