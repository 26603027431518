import Actions from '../Actions/Actions';
import { Dialog } from './Style';
import { ModalContainerProps } from './ModalContainer';

const Modal = ({ title, body, actions, isOpen, closeModal, options }: ModalContainerProps) => {
  const renderContent = () => {
    switch (typeof body) {
      case 'string':
        return <div className='main-container'>{body}</div>;
      default:
        return body;
    }
  };

  const renderActions = () => actions && <Actions actions={actions} />;

  return (
    <Dialog header={title} visible={isOpen} onHide={closeModal} {...options}>
      {renderContent()}
      {renderActions()}
    </Dialog>
  );
};

export default Modal;
