import { HoverComponentWrapper, Tag as PrimeTag, TagWrapper } from './Style';

import { ReactElement } from 'react';
import { TagProps } from 'primereact/tag';

export type SeverityType = TagProps['severity'] | 'warning';

type HoverComponent = {
  element: ReactElement | string;
};
interface iTagProps extends TagProps {
  hightlight?: string;
  onClick?: () => void;
  severity?: SeverityType;
  hoverComponent?: HoverComponent;
}

const Tag = ({ hightlight = 'true', rounded = true, hoverComponent, ...rest }: iTagProps) => {
  const renderHoverComponent = () => {
    return <HoverComponentWrapper className='hover-component-wrapper'>{hoverComponent?.element}</HoverComponentWrapper>;
  };

  const props = {
    ...rest,
    rounded,
    hightlight,
  };

  return (
    <TagWrapper className={`tag-wrapper`}>
      <PrimeTag {...props} />
      {hoverComponent?.element && renderHoverComponent()}
    </TagWrapper>
  );
};

export default Tag;
