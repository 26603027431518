import { Bot } from '../../../typings/states';

const getState = (state): Bot => state?.Bot;

export const getBotsList = (state) => getState(state)?.list;

export const getCurrentBot = (state) => getState(state)?.current;

export const getConsultationSpace = (state) => getState(state)?.consultationSpace;

export const getLanguages = (state) => getState(state)?.languages;
