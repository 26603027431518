import * as Types from './types';

import { action } from 'src/Tools/Redux';

/**
 * REDUCER ACTIONS
 */

export const setEnvironment = (env: string) => action(Types.SET_ENVIRONMENT, { env });

/**
 * SAGAS ACTIONS
 */

export const appInit = {
  request: () => action(Types.APP.INIT.REQUEST),
  success: () => action(Types.APP.INIT.SUCCESS),
  failure: (error: string) => action(Types.APP.INIT.FAILURE, { error }),
};

export const getAccountInfo = {
  request: (userName: string) => action(Types.ACCOUNT.GET.REQUEST, { userName }),
  success: () => action(Types.ACCOUNT.GET.SUCCESS),
  failure: (error: string) => action(Types.ACCOUNT.GET.FAILURE, { error }),
};

export const checkChatboxAvailability = {
  request: (version: string) => action(Types.CHATBOX.CHECK.REQUEST, { version }),
  success: () => action(Types.CHATBOX.CHECK.SUCCESS),
  failure: (error: any) => action(Types.CHATBOX.CHECK.FAILURE, { error }),
};
