import { CONFIG_TYPES } from 'src/Tools/Constants';
import LogoMeta from 'src/Assets/Images/logo-meta.png';
import LogoSlack from 'src/Assets/Images/logo-slack.png';
import LogoTeams from 'src/Assets/Images/logo-teams.png';
import LogoWebsite from 'src/Assets/Images/logo-website.png';
import LogoCallbot from 'src/Assets/Images/logo-callbot.png';
import NewConfig from './NewConfig';
import WithLayout from 'src/HOC/WithLayout/withLayout';
import { openModal } from 'src/Redux/Modal/actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export interface ConfigProps {
  title?: string;
  subtitle?: string;
  comment?: string;
  integrationList?: string[];
  isNew?: boolean;
  isFeatured?: boolean;
  disabled?: boolean;
  icon?: any;
}

type ConfigListProps = {
  [key: string]: ConfigProps;
};
export interface NewConfigContainerProps {
  openModal(body: any, title: string): void;
  configList: ConfigListProps;
}

const NewConfigContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const configList: ConfigListProps = {
    [CONFIG_TYPES.WEBSITE]: {
      title: t('newConfig.config.website.title'),
      subtitle: t('newConfig.config.website.subtitle'),
      integrationList: [t('general.website'), 'Wordpress', 'Service Now', 'Sharepoint'],
      isFeatured: true,
      icon: LogoWebsite,
    },
    [CONFIG_TYPES.TEAMS]: {
      title: t('newConfig.config.teams.title'),
      subtitle: t('newConfig.config.teams.subtitle'),
      icon: LogoTeams,
    },
    [CONFIG_TYPES.SLACK]: {
      title: t('newConfig.config.slack.title'),
      disabled: true,
      icon: LogoSlack,
    },
    [CONFIG_TYPES.META]: {
      title: t('newConfig.config.meta.title'),
      subtitle: t('newConfig.config.meta.subtitle'),
      integrationList: ['Facebook Messenger', 'WhatsApp', 'Instagram'],
      icon: LogoMeta,
    },
    [CONFIG_TYPES.CALLBOT]: {
      title: t('newConfig.config.callbot.title'),
      subtitle: t('newConfig.config.callbot.subtitle'),
      comment: t('newConfig.config.callbot.comment'),
      isNew: true,
      icon: LogoCallbot,
    },
  };

  const handleOpenModal = (body: any, title: string) => dispatch(openModal({ body, title }));

  const props: NewConfigContainerProps = {
    openModal: handleOpenModal,
    configList,
  };

  return <NewConfig {...props} />;
};

export default WithLayout(NewConfigContainer);
