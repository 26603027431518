import * as Constants from 'src/Tools/Constants';

import { createRequestTypes } from 'src/Tools/Redux';

/**
 * REDUX TYPES
 */
export const SET_SELECTED_CONFIGURATION = 'CHANNELS/SET_SELECTED_CONFIGURATION';

/**
 * CONFIGURATIONS REQUEST TYPES
 */
export const CONFIGURATIONS = createRequestTypes('CHANNELS/CONFIGURATIONS', [Constants.GET_ALL]);

/**
 * WEBSITE REQUEST TYPES
 */
export const WEBSITE_CONFIGURATION = createRequestTypes('CHANNELS/WEBSITE_CONFIGURATION', [
  Constants.CREATE_ONE,
  Constants.DELETE_ONE,
  Constants.GET_ALL,
  Constants.GET_ONE,
  Constants.IMPORT_ONE,
  Constants.PUBLISH_ONE,
  Constants.RESTORE_ONE,
  Constants.UPDATE_NAME,
  Constants.UPDATE_SLUG,
  Constants.UPDATE_ONE,
  Constants.UPLOAD_AVATAR,
  Constants.ZIP,
]);

export const WEBSITE_LOCAL_STORAGE = createRequestTypes('CHANNELS/WEBSITE_LOCAL_STORAGE', [
  Constants.UPDATE_IMAGES_DATA,
  Constants.UPDATE_CONTENT,
]);

export const TRANSLATION = createRequestTypes('CHANNELS/TRANSLATION', [
  Constants.DOWNLOAD,
  Constants.UPLOAD_EXCEL,
  Constants.UPLOAD_JSON,
]);

/**
 * CONNECTOR REQUEST TYPES
 */
export const CONNECTOR_CONFIGURATION = createRequestTypes('CHANNELS/CONNECTOR_CONFIGURATION', [
  Constants.CREATE_ONE,
  Constants.DELETE_ONE,
  Constants.GET_ALL,
  Constants.GET_ONE,
  Constants.UPDATE_NAME,
  Constants.UPDATE_ONE,
  Constants.UPLOAD_AVATAR,
  Constants.ZIP,
]);

/**
 * META REQUEST TYPES
 */
export const META_CONFIGURATION = createRequestTypes('CHANNELS/META_CONFIGURATION', [
  Constants.WEBHOOK,
  Constants.CREDENTIALS,
]);

export const META_LINKED_OBJECT = createRequestTypes('CHANNELS/META_LINKED_OBJECT', [
  Constants.CREATE_ONE,
  Constants.DELETE_ONE,
  Constants.UPDATE_ONE,
]);
