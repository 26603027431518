import { MainInputWrapper, MainLabel } from '../Style';

import { Slider as PrimeSlider } from 'primereact/slider';
import styled from 'styled-components';

export const SliderWrapper = styled(MainInputWrapper)`
  padding: 15px 15px 20px 15px;
  border-radius: 6px;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.color_grey__200};
  border: 1px solid ${({ theme }) => theme.color_grey__400};
  display: ${({ hidden }) => hidden && 'none'};
`;

export const Label = styled(MainLabel)`
  width: 100%;
  padding-bottom: 15px;
  font-size: 16px;
  color: ${({ theme }) => theme.color_grey__600};
`;

export const SliderContent = styled.div`
  width: 100%;
`;

export const Slider = styled(PrimeSlider)``;
