import styled from 'styled-components';

export const BreadCrumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .path-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .caret,
    .content {
      margin-right: 10px;
      font-size: 12px;
      display: flex;
      flex-direction: row;
      img {
        margin-right: 10px;
      }
    }
    .content {
      cursor: pointer;
      &:last-child {
        cursor: default;
        margin: 0;
        color: ${({ theme }) => theme.color_grey__600};
        font-weight: bold;
      }
    }
  }
`;
