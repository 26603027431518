import Button from '../Button/Button';

interface TranslationUploadProps {
  onClick: (lang: string) => void;
  label?: string;
  className?: string;
  loading?: boolean;
}

const TranslationDownload = ({ label, onClick, className, loading }: TranslationUploadProps) => {
  return <Button onClick={onClick} className={className} label={label} loading={loading} />;
};

export default TranslationDownload;
