import Actions, { ActionProps } from 'src/Components/Actions/Actions';

import { AppCredentialsProps } from 'src/Pages/Edition/Containers/Connector/Meta/Modals/AppCredentialsContainer';
import DocLink from './DocLink';
import Input from 'src/Components/Input/Input';
import { META_CONFIGURATION } from 'src/Redux/Configurations/types';
import { ModalWrapper } from './Style';
import { getLoadingStatus } from 'src/Redux/Loading/selectors';
import { getSelectedConfig } from 'src/Redux/Configurations/selectors';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AppCredentials = ({
  updateAppCredentials,
  onCancel,
  closeModal,
  edit = false,
  guideUrl,
}: AppCredentialsProps) => {
  const { t } = useTranslation();
  const selectedConfig = useSelector(getSelectedConfig);
  const isLoading = useSelector((state) => getLoadingStatus(state, META_CONFIGURATION.CREDENTIALS.REQUEST));
  const [clientId, setClientId] = useState(selectedConfig?.info.clientId || '');
  const [clientSecret, setClientSecret] = useState(selectedConfig?.info.clientSecret || '');

  const handleSetValues = () => updateAppCredentials(clientId, clientSecret);

  const disabledActions = clientId?.length < 1 || clientSecret?.length < 1;

  const actions: ActionProps[] = [
    {
      label: t('general.cancel'),
      className: 'p-button-text p-button-secondary',
      icon: 'icon-close',
      onClick: selectedConfig?.info.clientId ? closeModal : onCancel,
    },
    {
      label: selectedConfig?.info.clientSecret ? t('general.update') : t('general.save'),
      icon: 'icon-check',
      disabled: disabledActions,
      onClick: handleSetValues,
      loading: isLoading,
    },
  ];

  return (
    <ModalWrapper>
      <DocLink link={guideUrl} />
      <Input
        value={clientId}
        onChange={(e) => setClientId(e.target.value)}
        placeholder={t('edition.meta.modal.appCredentials.clientId')}
        label={t('edition.meta.modal.appCredentials.clientId')}
        disabled={edit}
      />
      <Input
        value={clientSecret}
        onChange={(e) => setClientSecret(e.target.value)}
        placeholder={t('edition.meta.modal.appCredentials.clientSecret')}
        label={t('edition.meta.modal.appCredentials.clientSecret')}
      />
      <Actions actions={actions} />
    </ModalWrapper>
  );
};

export default AppCredentials;
