/* eslint-disable @typescript-eslint/no-var-requires */
import dayjs from 'dayjs';
const localizedFormat = require('dayjs/plugin/localizedFormat');
const relativeTime = require('dayjs/plugin/relativeTime');

export const initDaysJs = () => {
  dayjs.extend(localizedFormat);
  dayjs.extend(relativeTime);
};

export const setDaysLocale = (lang: string) => dayjs.locale(lang);
