import { ConfigForm, FileUpload } from '../Style';

import Actions from 'src/Components/Actions/Actions';
import { CONFIG_TYPES } from 'src/Tools/Constants';
import { CreateConfigProps } from '../CreateConfigContainer';
import Icon from 'src/Components/Icon/Icon';
import Input from 'src/Components/Input/Input';
import Message from 'src/Components/Message/Message';
import { formatBytes } from 'src/Tools/Files';
import iconZip from 'src/Assets/Images/icon-zip.png';
import { useTranslation } from 'react-i18next';

const CreateType = ({
  createType,
  type,
  configName,
  configFile,
  selectFile,
  actions,
  setConfigName,
}: CreateConfigProps) => {
  const { t } = useTranslation();

  const renderReplaceMessage = () =>
    createType === 'replace' && <Message severity='warn' text={t('newConfig.modal.create_form.replace_config_warn')} />;

  const renderImportMessage = () =>
    type === CONFIG_TYPES.CUSTOMER && (
      <Message severity='info' text={t('newConfig.modal.create_form.import_config_info')} />
    );

  const renderFileInput = () =>
    createType &&
    ['import', 'replace'].includes(createType) && (
      <>
        <FileUpload onClick={selectFile}>
          {renderImportMessage()}
          <div className='label'>{t('newConfig.modal.create_form.file_label')}</div>
          <div className='content'>
            <div className='image'>
              <img src={iconZip} />
            </div>
            <div className='inner'>
              <div className='text'>
                <div className='name'>{configFile?.name || t('newConfig.modal.create_form.file_empty')}</div>
                <div className='size'>{formatBytes(configFile?.size)}</div>
              </div>
              <Icon icon='upload' className='upload-icon' color='white' />
            </div>
          </div>
          {renderReplaceMessage()}
        </FileUpload>
      </>
    );

  const renderNameInput = () =>
    createType !== 'replace' && (
      <Input
        className='config-name'
        label={t('newConfig.modal.create_form.input_label')}
        value={configName}
        placeholder={t('newConfig.modal.create_form.name.placeholder')}
        onChange={(e) => setConfigName(e.target.value)}
      />
    );

  return (
    <ConfigForm>
      {renderNameInput()}
      {renderFileInput()}
      <Actions actions={actions} />
    </ConfigForm>
  );
};

export default CreateType;
