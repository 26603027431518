import Actions from 'src/Components/Actions/Actions';
import { DropdownContainer, MainContainer, TemplateContainer } from './Style';
import { RestorePublicationProps } from './RestorePublicationContainer';
import { useTranslation } from 'react-i18next';
import useDate from '../../Hooks/useDate';
import { useSelector } from 'react-redux';
import { getUserLang } from '../../Redux/User/selectors';
import { Dropdown } from 'primereact/dropdown';
import { useState } from 'react';
import Tag from '../../Components/Tag/Tag';

const RestorePublication = ({
  publications,
  // publicationToRestore,
  setPublicationToRestore,
  actions,
}: RestorePublicationProps) => {
  const { t } = useTranslation();
  const userlang = useSelector(getUserLang);
  const { formatDateUseLocale } = useDate(userlang);
  const [selectedPublication, setSelectedPublication] = useState();

  const compareDatesPublication = (
    a: API_BACKEND.Schemas.ConfigurationPublicationDTO,
    b: API_BACKEND.Schemas.ConfigurationPublicationDTO
  ) => {
    return b.publishedAt - a.publishedAt;
  };

  const template = (option) => (
    <TemplateContainer>
      <div className='publication-template flex align-items-center'>
        <div className={'publication-version'}>
          <Tag value={option.version} severity={'success'}></Tag>
        </div>
        <div className={'publication-info'}>
          <div>
            {t('edition.restore.modal.dropdown.publication_info.published_at', {
              publishedAt: formatDateUseLocale(option.publishedAt),
            })}
          </div>
          {t('edition.restore.modal.dropdown.publication_info.publisher', {
            publisher: option.publisher,
          })}
        </div>
      </div>
    </TemplateContainer>
  );

  const selectedPublicationTemplate = (option, props) => {
    if (option) {
      return template(option);
    }

    return <span>{props.placeholder}</span>;
  };

  const publicationOptionTemplate = (option) => {
    return template(option);
  };

  const renderPublications = () => {
    publications.sort(compareDatesPublication);

    return (
      <DropdownContainer>
        <div id='publications-dropdown-container' className='card justify-content-center'>
          <Dropdown
            value={selectedPublication}
            options={publications}
            optionLabel={'version'}
            itemTemplate={publicationOptionTemplate}
            valueTemplate={selectedPublicationTemplate}
            placeholder={t('edition.restore.modal.dropdown.placeholder')}
            onChange={(e) => {
              e.value?.id && setPublicationToRestore(e.value.id);
              setSelectedPublication(e.value);
            }}
          />
        </div>
      </DropdownContainer>
    );
  };

  return (
    <MainContainer>
      <div className={'input-wrapper'}>{renderPublications()}</div>
      <br />
      <Actions actions={actions} />
    </MainContainer>
  );
};

export default RestorePublication;
