import * as Types from '../types';

import { action } from 'src/Tools/Redux';

/**
 * REDUCER ACTIONS
 */

export const setSelectedConfiguration: Redux.ActionFactory = (data) =>
  action(Types.SET_SELECTED_CONFIGURATION, { data });

/**
 * SAGAS ACTIONS
 */

export const getAllConfigurations = {
  request: (botData) => action(Types.CONFIGURATIONS.GET_ALL.REQUEST, { botData }),
  success: (data) => action(Types.CONFIGURATIONS.GET_ALL.SUCCESS, { data }),
  failure: (error) => action(Types.CONFIGURATIONS.GET_ALL.FAILURE, { error }),
};
