import * as Constants from './Constants';

export const action = (type: string, payload = {}) => ({ type, payload });

export const createRequestTypes = (base: string, operations: any) => {
  return operations.reduce((acc, op) => {
    acc[op] = [Constants.REQUEST, Constants.CANCEL, Constants.SUCCESS, Constants.FAILURE].reduce(
      (subacc, type) => ({
        ...subacc,
        [type]: `${base}_${op}_${type}`,
      }),
      {}
    );
    return acc;
  }, {});
};
