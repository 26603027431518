import { ColumnWrapper, Container, InnerContent } from 'src/Pages/Edition/Style';

import ControlledDropdown from 'src/Components/Dropdown/ControlledDropdown';
import ControlledInput from 'src/Components/Input/ControlledInput';
import ControlledSwitch from 'src/Components/Switch/ControlledSwitch';
import { DisplayProps } from '../Display';
import Message from 'src/Components/Message/Message';
import { useTranslation } from 'react-i18next';

interface AdditonalTabsProps extends DisplayProps {}

const AdditionalTabs = ({ watch, control }: AdditonalTabsProps) => {
  const { t } = useTranslation();

  const options = [
    {
      label: 'Activé',
      value: true,
    },
    {
      label: 'Désactivé',
      value: false,
    },
  ];

  const renderBannerPanel = () =>
    watch('content.banner.active') && (
      <div className='options-panel'>
        <Container className='know-more'>
          <ColumnWrapper className='link-action'>
            <ControlledDropdown
              options={options}
              label={t('edition.display.field.linkButton')}
              value={watch('content.banner.more')}
              control={control}
              className='more'
              name='content.banner.more'
            />
            <ControlledInput
              control={control}
              iconPos='left'
              icon='link'
              name='content.banner.moreLink'
              className='moreLink'
              rules={{
                pattern: {
                  value: /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm,
                  message: t('form.url.error'),
                },
              }}
            />
          </ColumnWrapper>
        </Container>
        <Container>
          <ColumnWrapper className='close-action'>
            <ControlledDropdown
              options={options}
              value={watch('content.banner.dismissable')}
              control={control}
              className='closeButton'
              label={t('edition.display.field.closeButton')}
              name='content.banner.dismissable'
            />
          </ColumnWrapper>
        </Container>
        <Message severity='info' wide text={t('edition.display.field.contactTabInfo')} />
      </div>
    );

  return (
    <ColumnWrapper className='banner-panel'>
      <InnerContent>
        <ControlledSwitch
          control={control}
          label={t('edition.display.field.contactTab')}
          icon='contact-book'
          name='content.tabs.hasContactTab'
        />
        {watch('content.tabs.hasContactTab') && (
          <Message severity='info' wide text={t('edition.display.field.contactTabInfo')} />
        )}
      </InnerContent>
      <InnerContent>
        <ControlledSwitch
          control={control}
          label={t('edition.display.field.infoBanner')}
          icon='bookmark'
          name='content.banner.active'
        />
        {renderBannerPanel()}
      </InnerContent>
    </ColumnWrapper>
  );
};

export default AdditionalTabs;
