import { ReactNode, useEffect, useMemo } from 'react';
import { formInterface, stepsInterface } from '../../EditionContainer';
import { useDispatch, useSelector } from 'react-redux';

import { APP_URL } from 'src/Tools/Constants';
import Informations from '../../../Components/Forms/Connector/Global/Informations';
import Pages from 'src/Pages/Edition/Components/Forms/Connector/Meta/Pages';
import { createMetaWebhook } from 'src/Redux/Configurations/actions/connectorActions';
import { getSelectedConfig } from 'src/Redux/Configurations/selectors';
import { getUserLang } from 'src/Redux/User/selectors';
import imageInstagram from 'src/Assets/Icons/icon-instagram.svg';
import imagePage from 'src/Assets/Icons/icon-page.svg';
import imageWhatsapp from 'src/Assets/Icons/icon-whatsapp.svg';
import { useTranslation } from 'react-i18next';

interface PageTypesProps {
  label: string;
  type: API_CONNECTOR.Schemas.MetaObjectDTO;
  icon: string;
  image?: any;
  disabled?: boolean;
}
export interface MetaStepProps extends formInterface {
  initCreatePage: (type: API_CONNECTOR.Schemas.MetaObjectDTO, callbackModal: ReactNode) => void;
  pageTypes: PageTypesProps[];
  selectedConfig: Models.ConnectorConfiguration;
  guideUrl: string;
}

const MetaContainer = (props: formInterface) => {
  const { currentStep, setStepperConfig } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedConfig = useSelector(getSelectedConfig);
  const userLang = useSelector(getUserLang);

  const guideUrl = useMemo(() => APP_URL[userLang === 'fr' ? 'fr' : 'en'].metaGuide, [userLang]);

  const pageTypes: PageTypesProps[] = [
    {
      label: t('edition.meta.page.instagram'),
      type: 'instagram',
      icon: 'icon-instagram',
      image: imageInstagram,
    },
    { label: t('edition.meta.page.messenger'), type: 'page', icon: 'icon-messenger', image: imagePage },
    {
      label: t('edition.meta.page.whatsapp'),
      type: 'whatsapp',
      icon: 'icon-whatsapp',
      image: imageWhatsapp,
    },
  ];

  const handleInitCreatePage = (type: API_CONNECTOR.Schemas.MetaObjectDTO, callbackModal) => {
    dispatch(createMetaWebhook.request(selectedConfig.id, type, callbackModal));
  };

  const stepProps: MetaStepProps = {
    ...props,
    selectedConfig,
    pageTypes,
    guideUrl,
    initCreatePage: handleInitCreatePage,
  };

  const steps: stepsInterface[] = [
    {
      label: 'edition.informations.title',
      body: <Informations {...stepProps} />,
    },
    {
      label: 'edition.pages.title',
      body: <Pages {...stepProps} />,
    },
  ];

  useEffect(() => {
    setStepperConfig(steps);
  }, []);

  return <div className='meta-container'>{steps[currentStep]?.body}</div>;
};

export default MetaContainer;
