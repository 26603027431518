import * as Constants from 'src/Tools/Constants';

import { formInterface, stepsInterface } from '../EditionContainer';
import { getUserHost, getUserProxy } from '../../../../Redux/User/selectors';
import {
  getWebsiteConfigurationZip,
  updateWebsiteConfiguration,
  downloadTranslation,
  uploadTranslationExcel,
} from 'src/Redux/Configurations/actions/websiteActions';
import { useDispatch, useSelector } from 'react-redux';

import Advanced from '../../Components/Forms/Website/Advanced';
import { CONFIG_TYPES } from 'src/Tools/Constants';
import Display from '../../Components/Forms/Website/Display';
import LocalStorage from 'src/Tools/LocalStorage';
import Publish from '../../Components/Forms/Website/Publish';
import SocialBot from 'src/Tools/bot.json';
import { getSelectedConfig, getImagesData } from 'src/Redux/Configurations/selectors';
import { readeFileAsBase64 } from 'src/Tools/Files';
import { removeProtocol } from 'src/Tools/Url';
import { useEffect } from 'react';

export interface WebsiteStepProps extends formInterface {
  deploy: (type: string, version?: string) => void;
  imageUpload: (file: File, type: string, field: any) => void;
  downloadZip: (type: string) => void;
  downloadTranslation: () => void;
  uploadTranslationExcel: (file: File, lang: string) => void;
  selectedConfig: Models.WebsiteConfiguration;
  imagesData: Models.ImagesData | null;
}

const WebsiteContainer = (props: formInterface) => {
  const dispatch = useDispatch();
  const selectedConfig = useSelector(getSelectedConfig);
  const imagesData = useSelector(getImagesData);
  const userHost = useSelector(getUserHost);
  const userProxy = useSelector(getUserProxy);

  const { currentStep, setStepperConfig, getValues, watch, setValue, handleFormSubmit } = props;

  const handleImageUpload = async (file: File, type: string, field: Models.WebsiteImageTypes) => {
    const base64: any = await readeFileAsBase64(file);
    setValue(field, base64);
    handleFormSubmit(() => handleUploadAvatar(file, type));
  };

  const handleDownloadTranslation = () =>
    selectedConfig?.id && dispatch(downloadTranslation.request(selectedConfig?.id));

  const handleUploadTranslationExcel = (file: File, lang?: string) => {
    file && selectedConfig?.id && dispatch(uploadTranslationExcel.request(selectedConfig?.id, file, lang));
  };

  const handleUploadAvatar = (file, type) =>
    dispatch(updateWebsiteConfiguration.request(getValues(), { uploadAvatar: true, file, type }));

  const handleDeploy = (env, version) =>
    dispatch(updateWebsiteConfiguration.request(getValues(), { publish: true, env, version }));

  const handleDownloadConfig = (env) => dispatch(getWebsiteConfigurationZip.request(env));

  const handleSubmitAndDeploy = (env, version) => handleFormSubmit(() => handleDeploy(env, version));

  const stepProps: WebsiteStepProps = {
    ...props,
    selectedConfig,
    imagesData: imagesData,
    deploy: handleSubmitAndDeploy,
    imageUpload: handleImageUpload,
    downloadZip: handleDownloadConfig,
    downloadTranslation: handleDownloadTranslation,
    uploadTranslationExcel: handleUploadTranslationExcel,
  };

  const steps: stepsInterface[] = [
    {
      label: 'edition.display.title.display',
      disabled: selectedConfig.type === CONFIG_TYPES.CUSTOMER,
      body: <Display {...stepProps} />,
    },
    {
      label: 'edition.advanced.title',
      disabled: selectedConfig.type === CONFIG_TYPES.CUSTOMER,
      body: <Advanced {...stepProps} />,
    },
    { label: 'edition.publish.title', body: <Publish {...stepProps} /> },
  ].filter((step) => !step.disabled);

  useEffect(() => {
    setStepperConfig(steps);
  }, []);

  useEffect(() => {
    const subscription = watch(() => {
      try {
        const botData = watch('content.oidc.enable')
          ? SocialBot
          : {
              backUpServer: '',
              bot: selectedConfig.botUUID,
              id: selectedConfig.botUUID,
              server: removeProtocol(userProxy) || userHost,
            };

        LocalStorage.setItem(Constants.DyduStorage.BOT, JSON.stringify(botData));

        LocalStorage.setItem(Constants.DyduStorage.CSS, JSON.stringify({ main: watch('theme.palette.primary.main') }));
        LocalStorage.setItem(Constants.DyduStorage.WIZARD, JSON.stringify(watch('content')));
      } catch (e) {
        console.log('errork', e);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return <div className='website-container'>{steps[currentStep]?.body}</div>;
};

export default WebsiteContainer;
