import Additional from './Informations/Additional';
import { EditionWrapper } from 'src/Pages/Edition/Style';
import General from './Informations/General';
import Heading from 'src/Components/Heading/Heading';
import { formInterface } from 'src/Pages/Edition/Containers/EditionContainer';
import { useTranslation } from 'react-i18next';

export interface InformationsProps extends formInterface {}

const Informations = (props: InformationsProps) => {
  const { t } = useTranslation();

  return (
    <EditionWrapper className='connector-informations'>
      {/** General */}
      <Heading as='h4' title={t('edition.generalInfo.title')} />
      <General {...props} />

      {/** Additional */}
      <Heading as='h4' title={t('edition.additionalInfo.title')} />
      <Additional {...props} />
    </EditionWrapper>
  );
};

export default Informations;
