import Button, { ButtonProps } from '../Button/Button';

import { Menu as PrimeMenu } from './Style';
import { useRef } from 'react';

interface MenuItem {
  label?: string;
  icon?: string;
  command?: () => void;
}

interface MenuGroupItem {
  label?: string;
  items?: MenuItem[];
}

interface MenuButtonProps extends ButtonProps {}

interface MenuProps extends MenuButtonProps {
  id?: string;
  model: MenuGroupItem[];
  popup?: boolean;
  loading?: boolean;
  className?: string;
  buttonLabel?: string;
  buttonIcon?: string;
  buttonIconPos?: 'right' | 'top' | 'bottom' | 'left';
  buttonClassName?: string;
}

const Menu = ({
  id,
  model,
  popup,
  className,
  buttonClassName,
  buttonIconPos,
  buttonLabel,
  buttonIcon,
  loading,
}: MenuProps) => {
  const menu = useRef<any>(null);
  const props = {
    id,
    model,
    popup,
    className,
    ref: menu,
  };

  const buttonProps: MenuButtonProps = {
    label: buttonLabel,
    className: buttonClassName,
    icon: buttonIcon,
    iconPos: buttonIconPos,
    loading,
    onClick: (event) => menu.current.toggle(event),
  };

  return (
    <>
      {popup && <Button {...buttonProps} />}
      <PrimeMenu {...props} />
    </>
  );
};

export default Menu;
