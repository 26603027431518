import { Header, TypeListContainer } from '../Style';

import DataTable from 'src/Components/DataTable/DataTable';
import Heading from 'src/Components/Heading/Heading';

interface TypeListProps {
  data: any[];
  columns: { id: string; title: string }[];
  openConfig: (id: string, type: string) => void;
  title: string;
  subtitle: string;
  action: any;
}

const TypeList = ({ data, columns, openConfig, title, subtitle, action }: TypeListProps) => {
  const renderHeader = () => (
    <Header direction='row'>
      <div className='title'>
        <Heading as='h3' title={title} />
        <Heading as='h4' title={subtitle} />
      </div>
      {action}
    </Header>
  );

  const handleOnRowClick = ({ data }) => !data.disabled && data?.id && data?.type && openConfig(data.id, data.type);

  const rowClass = (data) => ({
    disabled: data.disabled,
  });

  return (
    <TypeListContainer>
      <DataTable
        data={data}
        rows={5}
        columns={columns}
        rowClassName={rowClass}
        header={renderHeader}
        onRowClick={handleOnRowClick}
      />
    </TypeListContainer>
  );
};

export default TypeList;
