import styled, { css } from 'styled-components';

import { Chip as PrimeChip } from 'primereact/chip';

export const ChipWrapper = styled.div`
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ hightlight }: { hightlight: string }) =>
    hightlight === 'false' &&
    css`
      .p-chip {
        transition: all ease 0.5s;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    `}
`;

export const Chip = styled(PrimeChip)`
  white-space: nowrap;
  font-size: 14px;
`;
