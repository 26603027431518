import { SplitButton as PrimeSplitButton } from './Style';
import { SplitButtonProps as PrimeSplitButtonProps } from 'primereact/splitbutton';

export interface SplitButtonProps extends PrimeSplitButtonProps {
  loading?: boolean;
}

const SplitButton = ({
  label,
  onClick,
  disabled,
  children,
  icon,
  className = '',
  model,
  loading,
}: SplitButtonProps) => {
  const props: SplitButtonProps = {
    label,
    onClick,
    disabled,
    children,
    icon,
    model,
    loading,
    className,
  };
  return <PrimeSplitButton {...props} />;
};

export default SplitButton;
