import { createContext, useContext, useState } from 'react';
import Cas from '@dydu_ai/dydu-cas';
import { casClientName, casLoginMessage, casUrl } from '../Tools/Api';
import * as Constants from '../Tools/Constants';
import { setUserData } from '../Redux/User/actions';
import { useDispatch } from 'react-redux';
import LocalStorage from '../Tools/LocalStorage';

export interface UserContextProps {
  login: string;
  setLogin: (user: string) => void;
  casClient: any;
  nextUrl: string;
  encodedNextUrl: string;
}

export const UserContext = createContext({} as UserContextProps);

export const useUserContext = () => useContext<UserContextProps>(UserContext);

enum QueryParams {
  id = 'id',
  host = 'host',
  login = 'login',
  botUUID = 'botUUID',
  lang = 'lang',
  proxy = 'proxy',
}

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState<string>('');
  let url = window.location.href;
  console.log('userProvider run ' + window.location.href);
  if (url.includes('/#/auth')) {
    sessionStorage.setItem(Constants.CHANNELS_NEXT, url);
    url = url.replace('#', '/');
    const location = new URL(url);
    const queryParameters = new URLSearchParams(location.search);

    const id = queryParameters.get(QueryParams.id);
    const host = queryParameters.get(QueryParams.host);
    const login = queryParameters.get(QueryParams.login);
    const botUUID = queryParameters.get(QueryParams.botUUID);
    const lang = queryParameters.get(QueryParams.lang);
    const proxy = queryParameters.get(QueryParams.proxy);

    lang && LocalStorage.setItem('i18nextLng', lang);
    host && sessionStorage.setItem(Constants.DyduStorage.HOST, host);
    login && sessionStorage.setItem(Constants.DyduStorage.USERNAME, login);
    botUUID && sessionStorage.setItem(Constants.ATRIA_BOTUUID, botUUID);
    console.log(
      'id: ' +
        id +
        '\n' +
        'host: ' +
        sessionStorage.getItem(Constants.DyduStorage.HOST) +
        '\n' +
        'login: ' +
        login +
        '\n' +
        'botUUID: ' +
        botUUID +
        '\n' +
        'lang: ' +
        lang +
        '\n' +
        'Proxy: ' +
        proxy +
        '\n'
    );

    const userData = {
      id,
      host,
      userName: login,
      atriaBotUUID: botUUID,
      lang,
      Proxy: proxy,
    };

    dispatch(setUserData(userData));
  }

  const nextUrl = sessionStorage.getItem(Constants.CHANNELS_NEXT) || '';
  const encodedNextUrl = encodeURIComponent(nextUrl);

  const casClient = Cas.getInstance(
    casUrl,
    sessionStorage.getItem(Constants.DyduStorage.HOST) || '',
    '',
    casLoginMessage,
    casClientName
  );
  console.log('cas instantiated with ' + sessionStorage.getItem(Constants.DyduStorage.HOST));

  const props: UserContextProps = {
    login: user,
    setLogin: setUser,
    casClient,
    nextUrl: nextUrl,
    encodedNextUrl,
  };

  return <UserContext.Provider children={children} value={props} />;
};

export default UserProvider;
