import content from './content.json';
import translation from './translation.json';
import theme from './theme.json';
import contentSchema from './content-schema.json';
import translationSchema from './translation-schema.json';
import themeSchema from './theme-schema.json';
import { Release } from '../../../../../typings/states';

const release: Release = {
  version: 'current',
  content: content,
  contentSchema: contentSchema,
  translation: translation,
  translationSchema: translationSchema,
  theme: theme,
  themeSchema: themeSchema,
};

export default release;
