import { CONFIGURATIONS, WEBSITE_CONFIGURATION } from 'src/Redux/Configurations/types';
import { InnerContainer, MainContainer, VersionContainer } from './Style';

import HeaderContainer from 'src/Components/Header/HeaderContainer';
import Loader from 'src/Components/Loader/Loader';
import docVersion from 'src/version.json';

const WithLayout = (WrappedComponent: any) => {
  return (props) => {
    const renderLoader = () => (
      <Loader actions={[CONFIGURATIONS.GET_ALL.REQUEST, WEBSITE_CONFIGURATION.PUBLISH_ONE.REQUEST]} />
    );

    const renderVersion = () => docVersion?.branch && <VersionContainer>{docVersion.branch}</VersionContainer>;

    return (
      <MainContainer>
        <HeaderContainer />
        <InnerContainer>
          {renderLoader()}
          {renderVersion()}
          <WrappedComponent {...props} />
        </InnerContainer>
      </MainContainer>
    );
  };
};

export default WithLayout;
