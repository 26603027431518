import * as Types from './types';

import { action } from 'src/Tools/Redux';
import { ModalBodyTypes } from '../../../typings/states';

/**
 * REDUCER ACTIONS
 */

export const openModal: Redux.ActionFactory = ({
  title,
  body,
  actions,
  options,
}: {
  body?: ModalBodyTypes;
  title?: string;
  actions?: any;
  options?: any;
}) => action(Types.SET_MODAL_OPEN, { actions, body, title, options });

export const closeModal: Redux.ActionFactory = () => action(Types.SET_MODAL_CLOSE);

export const setModalTitle: Redux.ActionFactory = (title: string) => action(Types.SET_MODAL_TITLE, { title });
