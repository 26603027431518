import 'dayjs/locale/en';
import 'dayjs/locale/fr';

import dayjs from 'dayjs';
import { useCallback } from 'react';

const useDate = (lang: string) => {
  // To convert locale language when displaying last update date config.
  const formatDateUseLocale = useCallback(
    (props) => {
      if (lang === 'fr') {
        return `${dayjs(props).format('DD/MM/YYYY')} à ${dayjs(props).locale('fr').format('HH:mm')}`;
      } else {
        return `${dayjs(props).format('DD/MM/YYYY')} at ${dayjs(props).locale('en').format('h:mm A')}`;
      }
    },
    [lang]
  );

  return { formatDateUseLocale };
};

export default useDate;
