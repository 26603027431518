import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;

  > * {
    margin-bottom: 15px;
  }

  .page-list {
    padding: 20px;
    background: ${({ theme }) => theme.color_white};
    list-style: none;
    box-shadow: 0px 2px 4px #5060704d;

    li {
      display: flex;
      flex-direction: row;
      padding: 10px;
      align-items: center;
      border-bottom: 1px solid ${({ theme }) => theme.color_grey__400};
      transition: all ease 0.5s;

      &:hover {
        cursor: pointer;
        padding-right: 5px;
        background: ${({ theme }) => theme.background_main_blue};
      }

      .page-icon {
        margin-right: 5px;
      }

      .label {
        font-size: 16px;
        color: ${({ theme }) => theme.color_grey__600};
      }

      .cta {
        font-size: 14px;
        color: #7091d8;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0 0 auto;

        .cta-icon {
          margin: 0 5px;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }

  .app-info {
    border: 1px solid ${({ theme }) => theme.color_grey__300};
    border-radius: 6px;

    & > div {
      display: flex;
      flex-direction: row;
      padding: 10px 20px;
      font-size: 14px;
      color: ${({ theme }) => theme.color_grey__600};

      &:first-child {
        border-bottom: 1px solid ${({ theme }) => theme.color_grey__300};
      }

      .value {
        margin: 0 0 0 auto;
      }

      .cta {
        font-size: 14px;
        color: #7091d8;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0 0 auto;
        cursor: pointer;

        .cta-icon {
          margin: 0 5px;
        }
      }
    }
  }

  .documentation {
    box-shadow: 0px 2px 4px #5060704d;
    background: ${({ theme }) => theme.color_white};
    border-radius: 6px;

    .content {
      padding: 15px;

      .title {
        font-size: 21px;
        color: ${({ theme }) => theme.color_grey__600};
      }

      .subtitle {
        font-size: 14px;
        color: ${({ theme }) => theme.color_grey__500};
      }

      .action {
        margin-top: 10px;
        display: flex;
        justify-content: center;

        * {
          color: #7091d8;
        }
      }
    }
  }
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 15px 15px 15px;

  .page-type {
    margin-bottom: 15px;
  }

  .object-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    width: 100%;
    margin-bottom: 15px;
  }

  .page-object {
    background: ${({ theme }) => theme.color_white};
    box-shadow: 0px 2px 4px #5060704d;

    .actionWrapper {
      margin: 0;
      padding: 10px 5px 3px 15px;
    }

    .p-divider-horizontal {
      margin-top: 5px;
      margin-bottom: 10px;
    }

    .object-content {
      padding: 15px;
      display: flex;
      flex-direction: row;

      .heading {
        white-space: nowrap;
      }

      .informations {
        margin: 0 auto 0 0;
        width: 100%;

        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 10px;

          &.error {
            .value {
              color: ${({ theme }) => theme.color_critical};
            }
          }

          .title,
          .value {
            width: 33%;
          }
          .value {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
            white-space: nowrap;
            display: flex;
            justify-content: end;
            line-break: anywhere;
            max-height: 20px;
          }
        }
      }

      .permissions {
        width: 40%;

        ul {
          padding: 0;
          margin: 0;
          li {
            display: flex;
            align-items: center;
            margin: 0 0 10px 0;
            font-size: 14px;
            .icon-perm {
              font-size: 12px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
`;

export const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 300px;
  flex-direction: column;

  .image {
    max-width: 240px;
  }

  .content {
    font-size: 16px;
    color: #a5c2ed;
    margin-top: 30px;
  }
`;

export const ActionBody = styled.div`
  display: flex;
  flex-direction: row;
  .p-inline-message-text {
    white-space: nowrap;
  }

  > * {
    margin-left: 5px;
  }
`;
