import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
  height: 90vh;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .heading {
    &.title * {
      color: ${({ theme }) => theme.color_grey__600};
      font-size: 48px;
    }
    &.subtitle * {
      color: ${({ theme }) => theme.color_grey__500};
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
`;

export const ConfigsTypes = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  .column {
    display: flex;
    flex-direction: row;
  }
`;

interface ConfigProps {
  disabled?: boolean;
  onClick?: () => void;
}

export const Config = styled.div`
  width: 230px;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color_grey__400};
  background: ${({ theme }) => theme.color_white};
  position: relative;
  transition: all ease 0.5s;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: ${(props) => (props.className === 'callbot' ? 'none' : '0px 2px 4px #5060704d')};
    border-color: ${(props) => (props.className === 'callbot' ? '#B6C7D9' : '#7091d8')};
  }

  .featured {
    display: block;
    position: absolute;
    top: 0;
    right: 10px;
    background: ${({ theme }) => theme.color_information};
    border-radius: 0 0 15px 15px;
    width: 31px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .new {
    position: absolute;
    top: 20px;
    right: -30px;

    .p-chip {
      background: ${({ theme }) => theme.color_information};
      color: ${({ theme }) => theme.color_white};
    }
  }

  .logo {
    margin-bottom: 10px;
    img {
      margin: 0;
    }
  }

  .title {
    font-size: 18px;
    color: ${({ theme }) => theme.color_grey__600};
  }

  .subtitle,
  .list {
    font-size: 14px;
    font-style: italic;
    color: ${({ theme }) => theme.color_grey__500};
  }

  .list {
    padding: 20px 0 0 0;
  }

  .action {
    display: flex;
    justify-content: flex-end;
  }

  ${({ onClick }: ConfigProps) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ disabled }: ConfigProps) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;
