import styled from 'styled-components';

export const MainContainer = styled.div`
  .input-wrapper {
    width: 650px;
    min-width: 100%;
    max-width: 100%;
  }
`;

export const DropdownContainer = styled.div`
  #publications-dropdown-container {
    display: flex;
    flex-direction: column;
  }

  #publications-dropdown-container .p-dropdown-label,
  .p-dropdown-item {
    font-size: 14px;
  }
`;

export const TemplateContainer = styled.div`
  .publication-template {
    display: flex;
    flex-direction: row;
    font-size: 14px;
  }

  .publication-version {
    flex-grow: 0;
    margin: auto;
  }

  .publication-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }
`;
