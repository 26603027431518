import { ErrorButton, ErrorContainer, ErrorDesc, ErrorImage, ErrorMessage, ErrorTitle, ErrorWrapper } from './Style';

import { NODE_ENV } from 'src/Tools/Constants';
import log4javascript from 'log4javascript';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorProps {
  error: any;
  buttonLabel?: string;
  onClick?: () => void;
}

const Error = ({ error, onClick, buttonLabel }: ErrorProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    const loggerUrl =
      process.env.NODE_ENV === NODE_ENV.PRODUCTION
        ? window.DYDU_TARGETS?.logRelayUrl
        : process.env.REACT_APP_LOG_RELAY_URL;

    const log = log4javascript.getLogger();
    log4javascript.logLog.setQuietMode(true);

    if (loggerUrl) {
      const ajaxAppender = new log4javascript.AjaxAppender(loggerUrl);
      ajaxAppender.setSendAllOnUnload(true);
      ajaxAppender.setBatchSize(1);
      log.addAppender(ajaxAppender);
    }

    const consoleAppender = new log4javascript.BrowserConsoleAppender();
    log.addAppender(consoleAppender);

    log.error(error);
  }, [error]);

  return (
    <ErrorWrapper>
      <ErrorContainer>
        <ErrorImage src='Images/error-man.png' alt='' />
        <ErrorMessage>
          <ErrorTitle>{t('error_page.title')}</ErrorTitle>
          <ErrorDesc>{t('error_page.desc')}</ErrorDesc>
          {onClick && <ErrorButton label={t('error_page.cta') || buttonLabel} onClick={onClick} />}
        </ErrorMessage>
      </ErrorContainer>
    </ErrorWrapper>
  );
};

export default Error;
