import {
  getModalActions,
  getModalBody,
  getModalOpenState,
  getModalOptions,
  getModalTitle,
} from 'src/Redux/Modal/selectors';
import { useDispatch, useSelector } from 'react-redux';

import Modal from './Modal';
import { closeModal } from 'src/Redux/Modal/actions';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { ModalAction } from '../../../typings/states';

export interface ModalContainerProps {
  title?: string;
  body?: any;
  actions?: ModalAction[] | null;
  isOpen: boolean;
  closable?: boolean;
  closeModal: () => void;
  options: {
    closable?: boolean;
  };
}

const ModalContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isOpen = useSelector(getModalOpenState);
  const actions = useSelector(getModalActions);
  const body = useSelector(getModalBody);
  const title = useSelector(getModalTitle);
  const options = useSelector(getModalOptions);

  useEffect(() => {
    handleCloseModal();
  }, [location?.pathname]);

  const handleCloseModal = () => dispatch(closeModal());

  const props: ModalContainerProps = {
    title,
    body,
    actions,
    isOpen,
    closeModal: handleCloseModal,
    options,
  };

  return <Modal {...props} />;
};

export default ModalContainer;
