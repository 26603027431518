import { Modal } from '../../../typings/states';

const getState = (state): Modal => state?.Modal;

export const getModalOpenState = (state) => getState(state)?.isOpen;

export const getModalActions = (state) => getState(state)?.actions;

export const getModalTitle = (state) => getState(state)?.title;

export const getModalBody = (state) => getState(state)?.body;

export const getModalOptions = (state) => getState(state)?.options;
