import { closeModal, openModal } from 'src/Redux/Modal/actions';
import { useDispatch, useSelector } from 'react-redux';

import { ActionProps } from 'src/Components/Actions/Actions';
import LinkedObjectContainer from '../../Modals/LinkedObjectContainer';
import { META_LINKED_OBJECT } from 'src/Redux/Configurations/types';
import Object from 'src/Pages/Edition/Components/Forms/Connector/Meta/Pages/Dashboard/Object';
import { deleteMetaLinkedObject } from 'src/Redux/Configurations/actions/connectorActions';
import { getLoadingStatus } from 'src/Redux/Loading/selectors';
import { getSelectedConfig } from 'src/Redux/Configurations/selectors';
import { useTranslation } from 'react-i18next';

export interface ObjectContainerProps {
  data: API_CONNECTOR.Schemas.MetaLinkedObjectDTO;
  guideUrl: string;
}

export interface ObjectProps extends ObjectContainerProps {
  deleteObject: () => void;
  dotActions: any;
  guideUrl;
}

const ObjectContainer = ({ data, guideUrl }: ObjectContainerProps) => {
  const dispatch = useDispatch();
  const selectedConfig = useSelector(getSelectedConfig);
  const { t } = useTranslation();

  const isLoading = useSelector((state) => getLoadingStatus(state, META_LINKED_OBJECT.DELETE_ONE.REQUEST));

  const handleDeleteObject = () => data?.id && dispatch(deleteMetaLinkedObject.request(selectedConfig?.id, data?.id));

  const handleOpenModal = (title: string, body: any, actions?: any[]) => dispatch(openModal({ title, body, actions }));

  const deleteModalActions: ActionProps[] = [
    {
      label: t('general.cancel'),
      className: 'p-button-text p-button-secondary',
      icon: 'icon-close',
      onClick: () => dispatch(closeModal()),
    },
    {
      label: t('general.delete'),
      icon: 'icon-trash',
      loading: isLoading,
      onClick: handleDeleteObject,
      className: 'p-button-danger',
    },
  ];

  const dotActions = [
    {
      title: t('general.modify'),
      onClick: () =>
        handleOpenModal(
          t('general.modify'),
          <LinkedObjectContainer data={data} type={data.object} guideUrl={guideUrl} />
        ),
    },
    {
      title: t('configurations.dot_actions.delete'),
      type: 'error',
      onClick: () =>
        handleOpenModal(`${t('general.delete')} ${data.name || ''}`, t('delete_config.text'), deleteModalActions),
    },
  ];

  const props: ObjectProps = {
    data,
    dotActions,
    guideUrl,
    deleteObject: handleDeleteObject,
  };

  return <Object {...props} />;
};

export default ObjectContainer;
