import { Loading } from '../../../typings/states';

const getState = (state): Loading => state?.Loading;

export const getLoadingStatus = (state, action) => getState(state)[action];

export const getLoadingStatuses = (state, actions: Array<string>) => {
  let isLoading = false;

  for (const action of actions) {
    if (getState(state)[action]) {
      isLoading = true;
      break;
    }
  }

  return isLoading;
};
