import * as Constants from 'src/Tools/Constants';

import { DisplayProps } from '../Display';
import { Divider } from 'src/Components/Divider/Style';
import { InnerContent } from 'src/Pages/Edition/Style';
import LocalStorage from 'src/Tools/LocalStorage';
import Switch from 'src/Components/Switch/Switch';
import { getConsultationSpace } from 'src/Redux/Bot/selectors';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

interface ConsultationSpacesProps extends DisplayProps {}

const ConsultationSpaces = ({ setValue, watch }: ConsultationSpacesProps) => {
  const consultationSpaces = useSelector(getConsultationSpace);
  const { spaces } = watch('content');
  const spacesArray = spaces?.items;

  const defaultSpaceFromBot = consultationSpaces.find((space) => space.isDefault);

  const missingConsultationSpaces = spacesArray.filter(
    (space) => !consultationSpaces.map((cs) => cs.name).includes(space)
  );

  const checkDefaultValueFallback = () => {
    if (missingConsultationSpaces.length > 0) {
      const botName = defaultSpaceFromBot?.name || consultationSpaces[0].name;
      setValue('content.spaces.items', [botName]);
      handletSetLocalStorageSpace(botName);
    } else if (spacesArray.length > 0) {
      handletSetLocalStorageSpace(spacesArray[0]);
    } else {
      const botName = defaultSpaceFromBot?.name || consultationSpaces[0].name;
      setValue('content.spaces.items', [botName]);
      handletSetLocalStorageSpace(botName);
    }
  };

  useLayoutEffect(() => {
    checkDefaultValueFallback();
  }, []);

  const handletSetLocalStorageSpace = (str: string) => LocalStorage.setItem(Constants.DyduStorage.SPACE, str);

  const handleToggleSpace = (space: string, isActive?: boolean) => {
    const updatedSpaces = spacesArray?.includes(space) ? [...(spacesArray || [])] : [...(spacesArray || []), space];
    if (spacesArray?.indexOf(space) > -1) {
      const spacesValues = isActive ? updatedSpaces : spacesArray?.filter((item) => item !== space);
      setValue('content.spaces.items', spacesValues);
      handletSetLocalStorageSpace(spacesValues[0]);
    } else {
      setValue('content.spaces.items', updatedSpaces);
      handletSetLocalStorageSpace(space);
    }
  };

  return (
    <InnerContent className='consultation-spaces'>
      {consultationSpaces.map((space, index) => {
        const spaceName = space.name;
        const isActive = spaces?.items?.includes(spaceName);
        return (
          <div key={index}>
            <Switch
              label={space.name}
              checked={isActive}
              disabled={isActive && spacesArray.length === 1}
              onChange={() => handleToggleSpace(spaceName)}
            />
            {index !== consultationSpaces?.length - 1 && <Divider />}
          </div>
        );
      })}
    </InnerContent>
  );
};

export default ConsultationSpaces;
