import { User } from '../../../typings/states';

const getState = (state): User => state?.User;

export const getUserId = (state) => getState(state)?.userId;

export const getUserLang = (state) => getState(state)?.lang;

export const getUserName = (state) => getState(state)?.userName;

export const getUserHost = (state) => getState(state)?.host;

export const getUserProxy = (state) => getState(state)?.proxy;

export const getSelectedBot = (state) => getState(state)?.selectedBot;

export const getAtriaBotUUID = (state) => getState(state)?.atriaBotUUID;

export const getAtriaHost = (state) => getState(state)?.host;

export const getLoginStatus = (state) => getState(state)?.loggingIn;
