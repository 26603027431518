import Button from 'src/Components/Button/Button';
import Message from 'src/Components/Message/Message';
import { TemplateProps } from '../ImageUpload';
import { useTranslation } from 'react-i18next';

const Small = ({ title, subtitle, renderImage, handleFileSelect, error }: TemplateProps) => {
  const { t } = useTranslation();

  return (
    <div className='inner-content'>
      {title && <div className='title'>{title}</div>}
      {subtitle && <div className='title'>{subtitle}</div>}
      <div className='content'>
        <div className='image'>{renderImage()}</div>
        <div className='actions'>
          <Button
            icon='icon-upload'
            className='p-button-text p-button-secondary'
            onClick={handleFileSelect}
            label={t('general.modify')}
          />
        </div>
      </div>
      {error && <Message className='error-message' severity='error' text={error} />}
    </div>
  );
};

export default Small;
