import { DashboardContainer, EmptyList } from '../Style';

import ObjectWrapper from './Dashboard/ObjectWrapper';
import { PagesProps } from '../Pages';
import { groupByKey } from 'src/Tools/Array';
import imgEmptyList from 'src/Assets/Images/img-meta-empty.png';
import { useTranslation } from 'react-i18next';

export interface DashboardProps extends PagesProps {}

const Dashboard = ({ linkedObjects = [], pageTypes, guideUrl }: DashboardProps) => {
  const { t } = useTranslation();

  const renderList = () => {
    const groupedObjects = groupByKey(linkedObjects, 'object');
    return Object.keys({ ...groupedObjects })?.map((object) => {
      return (
        <ObjectWrapper
          key={object}
          type={object}
          pageTypes={pageTypes}
          data={groupedObjects?.[object]}
          guideUrl={guideUrl}
        />
      );
    });
  };
  const renderContent = () =>
    linkedObjects.length > 0 ? (
      renderList()
    ) : (
      <EmptyList>
        <img src={imgEmptyList} className='image' />
        <div className='content'>{t('general.emptyList')}</div>
      </EmptyList>
    );

  return <DashboardContainer>{renderContent()}</DashboardContainer>;
};

export default Dashboard;
