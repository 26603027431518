import { BrokenRelease } from '../../../../../../typings/states';
import brokenContentSchema from './content-schema.json';
import repairContentPatch from './content-repair-patch.json';
import nextRelease from '../../chatbox_edge_2023-08-22/chatbox_edge_2023-08-22';

const brokenRelease: BrokenRelease = {
  version: 'backend_edge_2023-08-22',
  brokenContentSchema: brokenContentSchema,
  repairContentPatch: repairContentPatch,
  nextRelease: nextRelease,
};

export default brokenRelease;
