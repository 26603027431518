import { Control, useController } from 'react-hook-form';
import { InputContent, InputSwitch, InputWrapper, Label } from './Style';

import Icon from 'src/Components/Icon/Icon';
import Tag from 'src/Components/Tag/Tag';
import { useRef } from 'react';

interface ControlledSwitchProps {
  control: Control<any>;
  name: string;
  defaultValue?: any;
  rules?: any;
  required?: boolean;
  triggerFocus?: boolean;
  wide?: boolean;
  onClick?: any;
  inputId?: string;
  value?: string;
  label?: string;
  subtitle?: string;
  icon?: string;
  tag?: {
    value: string;
    active?: boolean;
    onClick?: () => void;
  };
  className?: string;
}

const ControlledSwitch = ({
  control,
  name,
  label,
  subtitle,
  icon,
  defaultValue = '',
  rules,
  className = '',
  tag,
  required,
  onClick,
  wide,
  ...props
}: ControlledSwitchProps) => {
  const {
    field: { onChange, ...fields },
    fieldState,
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const switchRef = useRef<any>(null);

  const handleChange = () => onChange(!fields.value);

  const switchProps = {
    ...fields,
    ...props,
    onChange: onClick || handleChange,
    checked: fields.value,
    required,
    ref: switchRef,
  };

  const renderLabel = () =>
    label && (
      <Label onClick={handleChange}>
        {icon && <Icon icon={icon} />}
        <div className='label'>
          <div className='title'>{label}</div>
          <div className='subtitle'>{subtitle}</div>
        </div>
      </Label>
    );

  const renderTag = () => tag && <Tag {...tag} />;

  const renderContent = () => (
    <InputContent>
      {renderTag()}
      <InputSwitch {...switchProps} />
    </InputContent>
  );

  return (
    <InputWrapper wide={wide} className={`controlled-switch ${className}`}>
      {renderLabel()}
      {renderContent()}
      {fieldState?.error?.message}
    </InputWrapper>
  );
};

export default ControlledSwitch;
