import Button from 'src/Components/Button/Button';
import Message from 'src/Components/Message/Message';
import { TemplateProps } from '../ImageUpload';
import { useTranslation } from 'react-i18next';

const Large = ({
  title,
  subtitle,
  renderImage,
  handleFileSelect,
  error,
  extraContent,
  disabled,
  hidden,
}: TemplateProps) => {
  const { t } = useTranslation();

  const renderExtraContent = () => extraContent;

  const hideClass = disabled ? 'hide' : '';
  const filterImageHidden = hidden ? 'filter-hidden' : '';

  return (
    <div className='inner-content'>
      <div className={`image ${hideClass} ${filterImageHidden}`}>{renderImage()}</div>
      <div className={`content ${hideClass}`}>
        {title && <div className='title'>{title}</div>}
        {subtitle && <div className='subtitle'>{subtitle}</div>}
        <div className='subtitle'>SVG, JPG, PNG.</div>
        <div className='subtitle'>{t('general.file.maxSize')} 300Ko</div>
        {error && <Message className='error-message' severity='error' text={error} />}
      </div>
      <div className='actions'>
        <Button
          iconPos='right'
          icon='icon-upload'
          className='p-button-text p-button-secondary'
          onClick={handleFileSelect}
          label={t('general.modify')}
          disabled={hideClass ? true : false}
        />
        {renderExtraContent()}
      </div>
    </div>
  );
};

export default Large;
