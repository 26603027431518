import ControlledSwitch from 'src/Components/Switch/ControlledSwitch';
import { DisplayProps } from '../Display';
import Divider from 'src/Components/Divider/Divider';
import { InnerContent } from 'src/Pages/Edition/Style';
import { useTranslation } from 'react-i18next';

interface PrivacyProps extends DisplayProps {}

const Privacy = ({ control }: PrivacyProps) => {
  const { t } = useTranslation();

  return (
    <InnerContent className='privacy'>
      <ControlledSwitch
        control={control}
        label={t('edition.advanced.field.gdprDisclaimer.label')}
        subtitle={t('edition.advanced.field.gdprDisclaimer.subtitle')}
        icon='key'
        name='content.gdprDisclaimer.enable'
      />
      <Divider />
      <ControlledSwitch
        control={control}
        label={t('edition.advanced.field.sendGdprData.label')}
        subtitle={t('edition.advanced.field.sendGdprData.subtitle')}
        icon='download'
        name='content.moreOptions.sendGdprData'
      />
    </InnerContent>
  );
};

export default Privacy;
