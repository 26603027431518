import { ImageWrapper } from './Style';

interface IconProps {
  color?: string;
  width?: string | number;
  height?: string | number;
  src: string;
  onClick?: () => void;
  className?: string;
}

const Image = ({ className = '', width = 16, height = 16, src, onClick }: IconProps) => {
  const handleOnClick = () => onClick && onClick();

  return (
    <ImageWrapper className={`image-wrapper ${className}`} onClick={onClick && handleOnClick}>
      <img src={src} width={width} height={height} />
    </ImageWrapper>
  );
};

export default Image;
