import Button from 'src/Components/Button/Button';
import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 100vh;
  align-items: center;
`;

export const ErrorContainer = styled.div`
  max-width: 900px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ErrorImage = styled.img`
  width: 440px;
  height: 432px;
`;

export const ErrorMessage = styled.div`
  width: 470px;
`;

export const ErrorTitle = styled.div`
  color: #7091d8;
  font-size: 65px;
  font-weight: bold;
`;

export const ErrorDesc = styled.div`
  color: ${({ theme }) => theme.color_black};
  font-size: 20px;
`;

export const ErrorButton = styled(Button)`
  margin-top: 40px;
  color: #74889d;
  border-color: #74889d;
`;
