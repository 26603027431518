import { BrokenRelease } from '../../../../../../typings/states';
import brokenTranslationSchema from './translation-schema.json';
import repairTranslationPatch from './translation-repair-patch.json';
import nextRelease from '../../chatbox_edge_2023-05-16/chatbox_edge_2023-05-16';

const brokenRelease: BrokenRelease = {
  version: 'backend_edge_2023-05-16',
  brokenTranslationSchema: brokenTranslationSchema,
  repairTranslationPatch: repairTranslationPatch,
  nextRelease: nextRelease,
};

export default brokenRelease;
