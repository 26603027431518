import Heading from 'src/Components/Heading/Heading';
import ObjectContainer from 'src/Pages/Edition/Containers/Connector/Meta/Pages/Dashboard/ObjectContainer';
import { sortArrayByDate } from 'src/Tools/Date';

export interface ObjectWrapperProps {
  type: string;
  pageTypes: any;
  guideUrl: string;
  data: API_CONNECTOR.Schemas.MetaLinkedObjectDTO[];
}

const ObjectWrapper = (props: ObjectWrapperProps) => {
  const { pageTypes, type, data, guideUrl } = props;
  const object = pageTypes.find((t) => t.type === type);
  return (
    <div className='page-type'>
      <Heading as='h4' title={object?.label || type} image={object?.image} />
      <div className='object-wrapper'>
        {sortArrayByDate([...data], 'updatedAt')?.map((item) => (
          <ObjectContainer key={item?.id} data={item} guideUrl={guideUrl} />
        ))}
      </div>
    </div>
  );
};

export default ObjectWrapper;
