import { ColumnWrapper, InnerContent } from 'src/Pages/Edition/Style';

import { APP_URL } from 'src/Tools/Constants';
import ControlledInput from 'src/Components/Input/ControlledInput';
import { GuideDownloadButton } from '../Style';
import Icon from 'src/Components/Icon/Icon';
import { TeamsStepProps } from 'src/Pages/Edition/Containers/Connector/Teams/TeamsContainer';
import { getUserLang } from 'src/Redux/User/selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface GeneralProps extends TeamsStepProps {}

const General = ({ control }: GeneralProps) => {
  const { t } = useTranslation();

  const userLang = useSelector(getUserLang);

  const handleOpenDoc = () => {
    const url = userLang === 'fr' ? APP_URL['fr'].connectorGuide : APP_URL['en'].connectorGuide;
    window.open(url, '_blank');
  };

  const renderGuideDownloadButton = () => (
    <GuideDownloadButton onClick={handleOpenDoc}>
      <div className='title'>Guide de mise en place</div>
      <div className='subtitle'>Connectez votre chatbot à votre compte Teams en suivant le guide.</div>
      <div className='caret'>
        <Icon icon='caret-right' color='white' />
      </div>
    </GuideDownloadButton>
  );

  return (
    <InnerContent className='general'>
      <ColumnWrapper>
        {renderGuideDownloadButton()}
        <ControlledInput
          control={control}
          label={t('edition.configuration.microsoftAppId')}
          name='info.microsoftAppId'
          required
        />
        <ControlledInput
          control={control}
          label={t('edition.configuration.secretClient')}
          name='info.microsoftPassword'
          required
        />
      </ColumnWrapper>
    </InnerContent>
  );
};

export default General;
