import { Control, useController } from 'react-hook-form';
import { Dropdown, DropdownWrapper, Label } from './Style';

import { DropdownProps } from 'primereact/dropdown';

interface ControlledDropdownProps extends DropdownProps {
  control: Control<any>;
  label?: string;
  name: string;
}

const ControlledDropdown = ({
  control,
  name,
  label,
  defaultValue = '',
  placeholder,
  className = '',
  required,
  autoFocus,
  ...props
}: ControlledDropdownProps) => {
  const {
    field: { onChange, ...fields },
    fieldState,
  } = useController({
    name,
    control,
    defaultValue,
  });

  const change = (e) => onChange(e.target.value);

  const DropdownProps = {
    ...fields,
    ...props,
    onChange: change,
    placeholder,
    required,
    autoFocus,
    panelStyle: { fontSize: 14 },
    className: `${className} ${fieldState?.error ? 'p-invalid' : ''}`,
  };

  const renderLabel = () => label && <Label className='label'>{label}</Label>;

  const renderContent = () => <Dropdown {...DropdownProps} />;

  const renderError = () => fieldState?.error && <small className='p-error block'>{fieldState?.error?.message}</small>;

  return (
    <DropdownWrapper className={`controlled-dropdown ${className}`}>
      {renderLabel()}
      {renderContent()}
      {renderError()}
    </DropdownWrapper>
  );
};

export default ControlledDropdown;
