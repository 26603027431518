import { APP_PATH } from 'src/Tools/Constants';
import Button from 'src/Components/Button/Button';
import { Container } from './Style';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../Contexts/UserContext';

export const NoBot = () => {
  const navigate = useNavigate();
  const { casClient, encodedNextUrl } = useUserContext();

  const handleLogout = () => casClient.logout(encodedNextUrl);

  const handleRedirect = () => navigate(APP_PATH.CONFIGURATIONS);

  return (
    <Container className='flex flex-col justify-between items-center'>
      <header className='pt-10 mb-16'>
        <img src='Images/dydu-logo.svg' alt='logo dydu' onClick={handleRedirect} />
      </header>
      <div className='card flex flex-col justify-center items-center'>
        <img className='mb-16' src='Images/illustration_no-bot.svg' alt='empty illustration' />
        <h4 className='text-lg-2 text-action text--center text--bold mb-5'>No bot found</h4>
        <p className='text-sm text-grey-500 text--center mb-8'>
          Sorry, we were unable to find any bot accessible from your account. Please contact your administrator for
          further assistance.
        </p>
        <div className='actions'>
          <Button onClick={handleLogout} label='Logout' />
        </div>
      </div>
      <footer className='pt-16 mb-10'>
        <p className='text-sm text-grey-500'>© 2009 - 2021 dydu. Tous droits réservés.</p>
      </footer>
    </Container>
  );
};

export default NoBot;
