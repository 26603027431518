import { Large, Small } from './Templates';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { ButtonProps } from 'src/Components/Button/Button';
import Icon from 'src/Components/Icon/Icon';
import { ImageUploadWrapper } from './Style';
import { useTranslation } from 'react-i18next';

interface ImageUploadProps extends ButtonProps {
  title?: string;
  accept?: string;
  src?: string;
  size?: 'small' | 'large';
  onUpload: (file: File) => void;
  extraContent?: any;
  disabled?: boolean;
  hidden?: boolean;
}

export interface TemplateProps {
  title?: string;
  subtitle?: string;
  inputFile: any;
  accept?: string;
  error?: string | null;
  renderImage: () => ReactNode;
  handleFileSelect: () => void;
  handleFileChange: (e: any) => void;
  extraContent?: any;
  disabled?: boolean;
  hidden?: boolean;
}

const ImageUpload = ({ title, accept, src, onUpload, size, extraContent, disabled, hidden }: ImageUploadProps) => {
  const { t } = useTranslation();
  const inputFile = useRef<any>(null);
  const [currentFile, setCurrentFile] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [imageSrc, setImageSrc] = useState<string | undefined>(src);

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  useEffect(() => {
    if (currentFile) {
      handleValidate();
    }
  }, [currentFile]);

  const handleFileChange = (e) => {
    setError(null);
    const file = e.target.files[0];

    if (file.size > 304800) {
      setError(t('general.file.error.size', { size: '300kb' }));
      return;
    } else {
      setCurrentFile(file);
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        setImageSrc(event.target?.result as string);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const handleValidate = () => onUpload(currentFile);

  const handleFileSelect = () => inputFile.current.click();

  const renderImage = useCallback((): JSX.Element => {
    if (imageSrc) {
      return (
        <>
          <img src={imageSrc} data-testid='image upload' />
          {hidden && <Icon className='icon-invisible' color='black' icon='icon-invisible' size={10} />}
        </>
      );
    } else {
      return (
        <>
          <Icon icon='upload' data-testid='icon upload' />
        </>
      );
    }
  }, [imageSrc, hidden, src]);

  const props = {
    title,
    renderImage,
    handleFileSelect,
    inputFile,
    handleFileChange,
    accept,
    size,
    error,
    extraContent,
    disabled,
    hidden,
  };

  const renderTemplate = () => {
    switch (size) {
      case 'large':
        return <Large {...props} />;
      default:
        return <Small {...props} />;
    }
  };

  return (
    <ImageUploadWrapper className={`image-upload-${size}`} size={size}>
      {renderTemplate()}
      <input ref={inputFile} onChange={handleFileChange} type='file' accept={accept} data-testid='file-input' />
    </ImageUploadWrapper>
  );
};

export default ImageUpload;
