import { Divider as PrimeDivider } from './Style';

interface DividerProps {
  align?: 'center' | 'left' | 'right' | 'top' | 'bottom';
  layout?: 'horizontal' | 'vertical';
  type?: 'solid' | 'dashed' | 'dotted';
  className?: string;
}

const Divider = ({ align = 'center', layout = 'horizontal', type = 'solid', className = '' }: DividerProps) => {
  const props = {
    align,
    layout,
    type,
    className: `divider ${className}`,
  };

  return <PrimeDivider {...props} />;
};

export default Divider;
