import { DotActionsContainer, DotActionsList, DotActionsListWrapper, DotTrigger } from './Style';
import { useRef, useState } from 'react';

import Action from './Action';
import Icon from 'src/Components/Icon/Icon';
import Loader from 'react-spinners/ClipLoader';
import { useClickAway } from 'react-use';

export interface ActionsProps {
  title?: string;
  type?: string;
  visible?: boolean;
  disabled?: boolean;
  loading?: boolean;
  withSeparator?: boolean;
  onClick?: () => void;
}
interface DotActionsProps {
  actions: ActionsProps[];
  hasNotification?: boolean;
  baseIconColor?: string;
  hoverIconColor?: string;
  loading?: boolean;
}

const DotActions = ({
  actions = [],
  baseIconColor = '#74889D',
  hoverIconColor = '#33333B',
  hasNotification = false,
  loading = false,
}: DotActionsProps) => {
  const [open, setOpen] = useState(false);
  const [iconColor, setIconColor] = useState('#74889D');
  const dropDownRef = useRef<any>(null);

  useClickAway(dropDownRef, () => {
    setOpen(false);
  });

  const actionIsLoading = actions.filter((action) => action.loading).length > 0;

  const handleMouseEnter = () => hoverIconColor && setIconColor(hoverIconColor);

  const handleMouseLeave = () => setIconColor(baseIconColor);

  const handleSetOpen = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const renderItems = () =>
    actions?.map(
      (action, index) => action.visible !== false && <Action key={index} action={action} setOpen={setOpen} />
    );

  const renderDropDown = () =>
    open && (
      <DotActionsListWrapper ref={dropDownRef}>
        <DotActionsList>{renderItems()}</DotActionsList>
      </DotActionsListWrapper>
    );

  const renderIcon = () =>
    loading || actionIsLoading ? (
      <Loader loading={true} size={15} color={iconColor} />
    ) : (
      <Icon icon='dots' color={iconColor} />
    );

  return (
    <DotActionsContainer>
      <DotTrigger
        open={open}
        onClick={handleSetOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        hasNotification={hasNotification}
      >
        {renderIcon()}
      </DotTrigger>
      {renderDropDown()}
    </DotActionsContainer>
  );
};

export default DotActions;
