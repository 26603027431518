import { APP_PATH } from 'src/Tools/Constants';
import { ConnectorActionsProps } from '../Containers/ConnectorActionsContainer';
import DotActions from 'src/Components/DotActions/DotActions';
import UpdateNameContainer from 'src/Modals/UpdateName/UpdateNameContainer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RowActions = ({ config, openModal, deleteModalActions }: ConnectorActionsProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dotActions = [
    {
      title: t('configurations.dot_actions.edit_config'),
      onClick: () => navigate(`${APP_PATH.CONFIGURATIONS}/${config.type}/${config.id}`),
    },
    {
      title: t('configurations.dot_actions.edit_name'),
      onClick: () =>
        openModal(t('configurations.title_modal_update_config_name'), <UpdateNameContainer config={config} />),
    },
    {
      title: t('configurations.dot_actions.delete'),
      type: 'error',
      onClick: () => openModal(`${t('general.delete')} ${config.name}`, t('delete_config.text'), deleteModalActions),
    },
  ];

  return <DotActions actions={dotActions} />;
};

export default RowActions;
