import { Control, useController } from 'react-hook-form';
import { Label, Slider, SliderContent, SliderWrapper } from './Style';
import { useEffect, useState } from 'react';

import { textToInt } from 'src/Tools/Text';

interface ControlledSliderProps {
  control: Control<any>;
  name: string;
  defaultValue?: any;
  rules?: any;
  required?: boolean;
  autoFocus?: boolean;
  triggerFocus?: boolean;
  inputId?: string;
  value?: string;
  label?: string;
  hidden?: boolean;
  className?: string;
  step?: number;
  max?: number;
  min?: number;
}

const ControlledSlider = ({
  control,
  name,
  label,
  hidden,
  defaultValue = '',
  rules,
  className = '',
  required,
  autoFocus,
  step,
  max = 300,
  ...props
}: ControlledSliderProps) => {
  const {
    field: { onChange, ...fields },
    fieldState,
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(textToInt(fields.value));
  }, [fields.value]);

  const handleSlideEnd = () => onChange(value);

  const handleSetValue = (e) => setValue(e.value);

  const switchProps = {
    ...fields,
    ...props,
    onChange: handleSetValue,
    onSlideEnd: handleSlideEnd,
    value,
    required,
    max,
    autoFocus,
  };

  const renderLabel = () =>
    label && (
      <Label>
        {label} : <strong>{value}</strong>
      </Label>
    );

  const renderContent = () => (
    <SliderContent>
      <Slider step={step} {...switchProps} />
    </SliderContent>
  );

  return (
    <SliderWrapper className={className} hidden={hidden}>
      {renderLabel()}
      {renderContent()}
      {fieldState?.error?.message}
    </SliderWrapper>
  );
};

export default ControlledSlider;
