import { useDispatch, useSelector } from 'react-redux';

import AppCredentials from 'src/Pages/Edition/Components/Forms/Connector/Meta/Modals/AppCredentials';
import { closeModal } from 'src/Redux/Modal/actions';
import { getSelectedConfig } from 'src/Redux/Configurations/selectors';
import { updateMetaAppCredentials } from 'src/Redux/Configurations/actions/connectorActions';

export interface AppCredentialsContainerProps {
  edit?: boolean;
  onCancel?: () => void;
  guideUrl: string;
}

export interface AppCredentialsProps extends AppCredentialsContainerProps {
  updateAppCredentials: (clientId: string, clientSecret: string) => void;
  closeModal: () => void;
  guideUrl: string;
}

const AppCredentialsContainer = (props: AppCredentialsContainerProps) => {
  const dispatch = useDispatch();
  const selectedConfig = useSelector(getSelectedConfig);

  const handleUpdateAppCredentials = (clientId: string, clientSecret: string) => {
    dispatch(updateMetaAppCredentials.request(selectedConfig.id, { clientId, clientSecret }));
  };

  const handleCloseModal = () => dispatch(closeModal());

  const modalProps: AppCredentialsProps = {
    ...props,
    closeModal: handleCloseModal,
    updateAppCredentials: handleUpdateAppCredentials,
  };

  return <AppCredentials {...modalProps} />;
};

export default AppCredentialsContainer;
