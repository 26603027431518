import * as Constants from 'src/Tools/Constants';

import { createRequestTypes } from 'src/Tools/Redux';

/**
 * REDUX TYPES
 */
export const SET_CURRENT_BOT = 'CHANNELS/SET_CURRENT_BOT';

/**
 * BOT REQUEST TYPES
 */
export const BOT = createRequestTypes('CHANNELS/BOT', [
  Constants.CONSULTATION_SPACES,
  Constants.GET_ALL,
  Constants.LANGUAGES,
]);
