import { toastError, toastSuccess } from 'src/Tools/Toast';

import i18next from 'i18next';

export const textToInt = (str: string | number) => {
  if (typeof str === 'string') {
    return parseInt(str);
  }
  return str;
};

export const lowercaseFirstLetter = (string: string) => {
  if (typeof string !== 'string') return '';

  return `${string.charAt(0).toLowerCase()}${string.slice(1)}`;
};

export const upercaseFirstLetter = (string: string) => {
  if (typeof string !== 'string') return '';

  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};

export const copyToClipboard = (str: string, successStr?: string) => {
  try {
    str && navigator.clipboard.writeText(str);
    toastSuccess(successStr || i18next.t('general.copytoclipbord.success'));
  } catch {
    toastError(i18next.t('general.copytoclipbord.error'));
  }
};

export const isColor = (color: string) => {
  const reg = /^#([0-9a-f]{3}){1,2}$/i;
  return !reg.test(color);
};

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num);
};

/**
 * Encode a string of text as base64
 *
 * @param data The string of text.
 * @returns The base64 encoded string.
 */
export const encodeBase64 = (data: string) => {
  if (typeof btoa === 'function') {
    return btoa(data);
  } else if (typeof Buffer === 'function') {
    return Buffer.from(data, 'utf-8').toString('base64');
  } else {
    throw new Error('Failed to determine the platform specific encoder');
  }
};

/**
 * Decode a string of base64 as text
 *
 * @param data The string of base64 encoded text
 * @returns The decoded text.
 */
export const decodeBase64 = (data: string) => {
  if (typeof atob === 'function') {
    return atob(data);
  } else if (typeof Buffer === 'function') {
    return Buffer.from(data, 'base64').toString('utf-8');
  } else {
    throw new Error('Failed to determine the platform specific decoder');
  }
};
