import { EditionWrapper, InnerContent } from 'src/Pages/Edition/Style';

import Button from 'src/Components/Button/Button';
import Message from 'src/Components/Message/Message';
import { TeamsStepProps } from 'src/Pages/Edition/Containers/Connector/Teams/TeamsContainer';
import { useTranslation } from 'react-i18next';

export interface DownloadProps extends TeamsStepProps {}

const Download = ({ watch, downloadZip, setCurrentStep }: DownloadProps) => {
  const { t } = useTranslation();

  const hasError =
    !watch('info.microsoftAppId') ||
    !watch('info.microsoftPassword') ||
    !watch('info.connectorManifestVariables.fullName') ||
    !watch('info.connectorManifestVariables.fullDescription') ||
    !watch('info.connectorManifestVariables.shortName') ||
    !watch('info.connectorManifestVariables.shortDescription') ||
    !watch('info.connectorManifestVariables.companyName');

  const isValidMicrosoftAppId = () => {
    const microsoftAppId = watch('info.microsoftAppId');
    return /^[0-9A-Fa-f]{8}-([0-9A-Fa-f]{4}-){3}[0-9A-Fa-f]{12}$/.test(microsoftAppId);
  };

  const renderInfoMessage = () => {
    return hasError && isValidMicrosoftAppId() ? (
      <Message
        severity='warn'
        onClick={() => setCurrentStep('previous')}
        text={t('edition.download.type.teams.missingFields')}
        wide
      />
    ) : !isValidMicrosoftAppId() ? (
      <Message
        severity='error'
        onClick={() => setCurrentStep('previous')}
        text={t('edition.download.type.teams.wrongAppIdMessage')}
        wide
      />
    ) : null;
  };

  return (
    <EditionWrapper className='connector-download'>
      <InnerContent className={`inner-content ${watch('type')}`}>
        <div className='header'></div>
        <div className='content'>
          <div className='title'>{t('edition.download.content.title', { type: 'Microsoft Teams' })}</div>
          <div className='file'>
            <div className='info'>
              <div className='img' />
              <div className='filename'>Configuration_{watch('type')}.zip</div>
            </div>
          </div>
          {renderInfoMessage()}
          <div className='action'>
            <Button
              disabled={hasError || !isValidMicrosoftAppId()}
              icon='icon-download'
              label={t('edition.download.content.action')}
              onClick={downloadZip}
            />
          </div>
        </div>
      </InnerContent>
    </EditionWrapper>
  );
};

export default Download;
