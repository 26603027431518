import { AVATAR_TYPE, UPLOAD_TYPE } from 'src/Tools/Constants';

import ActionWrapper from 'src/Components/ActionWrapper/ActionWrapper';
import Divider from 'src/Components/Divider/Divider';
import ImageUpload from 'src/Components/ImageUpload/ImageUpload';
import { InnerContent } from 'src/Pages/Edition/Style';
import { TeamsStepProps } from 'src/Pages/Edition/Containers/Connector/Teams/TeamsContainer';
import { useTranslation } from 'react-i18next';

interface ApplicationProps extends TeamsStepProps {}

const Identity = ({ imageUpload, watch }: ApplicationProps) => {
  const { t } = useTranslation();

  const avatarTypeColor = watch('avatars')?.find((avatar) => avatar.avatarType === AVATAR_TYPE.COLOR.toLowerCase());
  const avatarTypeOutline = watch('avatars')?.find((avatar) => avatar.avatarType === AVATAR_TYPE.OUTLINE.toLowerCase());

  const imageList = [
    {
      title: t('edition.identity.icon.title'),
      subtitle: t('edition.identity.icon.subtitle'),
      src: avatarTypeColor?.avatarByte,
      type: AVATAR_TYPE.COLOR,
    },
    {
      title: t('edition.identity.iconOutlined.title'),
      subtitle: t('edition.identity.iconOutlined.subtitle'),
      src: avatarTypeOutline?.avatarByte,
      type: AVATAR_TYPE.OUTLINE,
    },
  ];

  const handleImageUpload = (file: File, id: string, avatarType: string, connectorType: string, index: number) =>
    imageUpload(file, avatarType, id, connectorType, index);

  const renderImagesUpload = () =>
    imageList.map((image, index) => (
      <div key={image.type}>
        <ActionWrapper
          title={image.title}
          subtitle={image.subtitle}
          body={
            <ImageUpload
              src={image.src}
              onUpload={(file) => handleImageUpload(file, image.type, watch('id'), watch('type'), index)}
              accept={UPLOAD_TYPE.IMAGE}
            />
          }
        />
        {index !== imageList.length - 1 && <Divider />}
      </div>
    ));

  return <InnerContent className='identity'>{renderImagesUpload()}</InnerContent>;
};

export default Identity;
