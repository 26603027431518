import styled, { css } from 'styled-components';

import { Tag as PrimeTag } from 'primereact/tag';

export const TagWrapper = styled.div`
  width: 100%;
`;

export const Tag = styled(PrimeTag)`
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  padding-right: 10px;
  padding-left: 10px;

  &.p-tag {
    background: #e2e9f0;
    color: ${({ theme }) => theme.color_grey__600};
  }

  &.p-tag-warning {
    background: #f9f6bc;
    color: #f3b942;
  }

  &.p-tag-success {
    background: #bfecdb;
    color: #2cbf87;
  }

  ${({ hightlight }: { hightlight: string }) =>
    hightlight === 'false' &&
    css`
      transition: all ease 0.5s;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    `}
`;

export const HoverComponentWrapper = styled.div`
  display: none;
  position: absolute;
  top: -80px;
  right: 0;
  max-width: 300px;
  min-width: 230px;
  @media screen and (min-width: 961px) {
    top: -5px;
    max-width: 600px;
    left: calc(100% - 20px);
  }
  @media screen and (min-width: 1200px) {
    left: calc(100% - 70px);
  }
  @media screen and (min-width: 1500px) {
    left: calc(100% - 120px);
  }
`;
