import * as Constants from 'src/Tools/Constants';

import { createRequestTypes } from 'src/Tools/Redux';

export const INIT = 'INIT';
export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';

export const APP = createRequestTypes('APP', [INIT]);

export const ACCOUNT = createRequestTypes('APP/ACCOUNT', [Constants.GET]);

export const CHATBOX = createRequestTypes('APP/CHATBOX', [Constants.CHECK]);
