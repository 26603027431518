import * as Types from './types';

import { produce } from 'immer';
import { User } from '../../../typings/states';

const initialState: User = {
  host: '',
  proxy: null,
  lang: 'fr',
  loggingIn: false,
  atriaBotUUID: null,
  selectedBot: null,
  userId: null,
  userName: null,
};

const UserReducer = (state = initialState, { type, payload }: Redux.Action): User =>
  produce(state, (draft) => {
    switch (type) {
      case Types.SET_ATRIA_HOST:
        draft.host = payload.host;
        draft.proxy = payload.proxy;
        break;

      case Types.SET_USER_DATA:
      case Types.SET_CAS_DATA: {
        const {
          data: { host, lang, atriaBotUUID, id, userName, proxy },
        } = payload;
        draft.host = host || state.host;
        draft.proxy = proxy || state.proxy;
        draft.atriaBotUUID = atriaBotUUID || state.atriaBotUUID;
        draft.lang = lang || state.lang;
        draft.loggingIn = true;
        draft.userName = userName || state.userName;
        draft.userId = id || state.userId;
        break;
      }

      case Types.LOGOUT:
        draft.host = state.host;
        draft.atriaBotUUID = state.atriaBotUUID;
        draft.lang = state.lang;
        draft.loggingIn = false;
        draft.proxy = null;
        break;

      default:
        return draft;
    }
  });

export default UserReducer;
