import { InnerContent } from 'src/Pages/Edition/Style';

import ControlledInput from 'src/Components/Input/ControlledInput';
import { TeamsStepProps } from 'src/Pages/Edition/Containers/Connector/Teams/TeamsContainer';
import { useTranslation } from 'react-i18next';
import ControlledSwitch from '../../../../../../../Components/Switch/ControlledSwitch';
import ControlledSlider from '../../../../../../../Components/Slider/ControlledSlider';
import { OptionsPanel } from '../Style';
import Divider from '../../../../../../../Components/Divider/Divider';

interface OptionsProps extends TeamsStepProps {}

const Options = ({ control, watch }: OptionsProps) => {
  const { t } = useTranslation();

  const isDissatisfactionOptionEnabled = watch && watch('info.dissatisfactionOptions.enabled.value');

  return (
    <InnerContent>
      <OptionsPanel>
        <ControlledSwitch
          control={control}
          label={t('edition.options.dissatisfaction.field.enabled')}
          icon='thumb-down'
          name='info.dissatisfactionOptions.enabled.value'
          defaultValue={false}
        />
        <ControlledInput
          wide
          control={control}
          hidden={!isDissatisfactionOptionEnabled}
          label={t('edition.options.dissatisfaction.field.knowledgeTrigger')}
          delay={500}
          name='info.dissatisfactionOptions.knowledgeTrigger.value'
          className={isDissatisfactionOptionEnabled ? 'optionField' : ''}
        />
        <ControlledSlider
          control={control}
          hidden={!isDissatisfactionOptionEnabled}
          label={t('edition.options.dissatisfaction.field.consecutiveTarget')}
          name='info.dissatisfactionOptions.consecutiveTarget.value'
          className={isDissatisfactionOptionEnabled ? 'optionField' : ''}
          step={1}
          min={1}
          max={10}
          defaultValue={2}
        />
      </OptionsPanel>
      <Divider />
      <OptionsPanel>
        <ControlledSwitch
          control={control}
          label={t('edition.options.technicalWelcome.field.show')}
          icon='eye'
          name='info.technicalWelcomeOption.show.value'
          defaultValue={false}
        />
      </OptionsPanel>
    </InnerContent>
  );
};

export default Options;
