import { HeadingWrapper } from './Style';

interface HeadingProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  title: string;
  className?: string;
  action?: any;
  image?: any;
}

const Heading = ({ as, image, title, action, className = '' }: HeadingProps) => {
  const renderHeadingType = () => {
    switch (as) {
      case 'h1':
        return <h1>{title}</h1>;
      case 'h2':
        return <h2>{title}</h2>;
      case 'h3':
        return <h3>{title}</h3>;
      case 'h4':
        return <h4>{title}</h4>;
      case 'h5':
        return <h5>{title}</h5>;
      case 'h6':
        return <h6>{title}</h6>;
      default:
        return <h2>{title}</h2>;
    }
  };

  const renderAction = () => action && <span>{action}</span>;

  return (
    <HeadingWrapper className={`heading ${as} ${className}`}>
      <div className='text'>
        {image && <img src={image} />}
        {renderHeadingType()}
      </div>
      {renderAction()}
    </HeadingWrapper>
  );
};

export default Heading;
