import * as Actions from './actions';
import * as Constants from 'src/Tools/Constants';
import * as Types from './types';

import { all, call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { getAtriaBotUUID, getUserId } from 'src/Redux/User/selectors';

import { ApiProps } from '@dydu_ai/dydu-api';
import { getClientApi } from 'src/Tools/Api';
import { push } from '@lagunovsky/redux-react-router';
import { toastError } from 'src/Tools/Toast';

/**
 * API CALLS
 */

/**
 * Get Bots list
 * @returns
 */
function* getBots() {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const userId = yield select(getUserId);
    const { data: botsList } = yield* call(Client.atria.getBots, userId);

    if (!Array.isArray(botsList)) {
      yield put(push(Constants.APP_PATH.NO_BOT));
    }

    const atriaBotUUID = yield select(getAtriaBotUUID);
    const authBotUUID = sessionStorage.getItem(Constants.ATRIA_BOTUUID);

    const selectedBot = botsList.find((bot) => bot.botUUID === (authBotUUID || atriaBotUUID));

    const defaultBot = botsList.find((bot) => bot.botDefault === true);

    const currentBot = selectedBot || defaultBot || botsList[0];

    yield put(Actions.setCurrentBot(currentBot));
    yield put(Actions.getBots.success(botsList));
  } catch (error: any) {
    yield put(Actions.getBots.failure(error));
    toastError(error);
    yield put(push(Constants.APP_PATH.LOGOUT));
  }
}

/**
 * Get Consultation Spaces
 * @param botUUID
 * @param defaultLanguage
 * @returns
 */
function* getConsultationSpaces({ payload: { botUUID, defaultLanguage } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const { data } = yield* call(Client.atria.getConsultationSpaces, botUUID, defaultLanguage);
    yield put(Actions.getConsultationSpaces.success(data));
  } catch (error: any) {
    yield put(Actions.getConsultationSpaces.failure(error));
    toastError(error);
  }
}

/**
 * Get Bot available languages
 * @param botUUID
 * @returns
 */
function* getBotLanguages({ payload: { botUUID } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const { data } = yield* call(Client.atria.getBotLanguages, botUUID);
    yield put(Actions.getBotLanguages.success(data));
  } catch (error) {
    yield put(Actions.getBotLanguages.failure(error));
    toastError(error);
  }
}

/**
 * Bot Saga
 */
export default function* botSaga() {
  try {
    yield all([
      takeEvery(Types.BOT.GET_ALL.REQUEST, getBots),
      takeEvery(Types.BOT.CONSULTATION_SPACES.REQUEST, getConsultationSpaces),
      takeLatest(Types.BOT.LANGUAGES.REQUEST, getBotLanguages),
    ]);
  } catch (error: any) {
    toastError(error);
  }
}
