import { MainInputWrapper, MainLabel } from 'src/Components/Style';

import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import styled from 'styled-components';

export const DropdownWrapper = styled(MainInputWrapper)`
  flex-direction: column;
`;

export const Label = styled(MainLabel)``;

export const Dropdown = styled(PrimeDropdown)`
  .p-dropdown-label {
    font-size: 14px;
  }
`;
