import ActionWrapper from 'src/Components/ActionWrapper/ActionWrapper';
import ControlledInput from 'src/Components/Input/ControlledInput';
import Divider from 'src/Components/Divider/Divider';
import { InformationsProps } from '../../Global/Informations';
import { InnerContent } from 'src/Pages/Edition/Style';
import { useTranslation } from 'react-i18next';

interface ApplicationProps extends InformationsProps {}

const Links = ({ control }: ApplicationProps) => {
  const { t } = useTranslation();

  const linksRule = {
    pattern: {
      value: /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm,
      message: t('form.url.error'),
    },
  };
  return (
    <InnerContent className='links'>
      <ActionWrapper
        title={t('edition.links.urlTermsOfUse')}
        subtitle={t('edition.links.withProtocol')}
        body={
          <ControlledInput control={control} name='info.connectorManifestVariables.urlTermsOfUse' rules={linksRule} />
        }
      />
      <Divider />
      <ActionWrapper
        title={t('edition.links.urlWebsite')}
        subtitle={t('edition.links.withProtocol')}
        body={<ControlledInput control={control} name='info.connectorManifestVariables.urlWebsite' rules={linksRule} />}
      />
      <Divider />
      <ActionWrapper
        title={t('edition.links.urlPrivacyPolicy')}
        subtitle={t('edition.links.withProtocol')}
        body={
          <ControlledInput
            control={control}
            name='info.connectorManifestVariables.urlPrivacyPolicy'
            rules={linksRule}
          />
        }
      />
    </InnerContent>
  );
};

export default Links;
