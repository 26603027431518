import DotLoader from 'react-spinners/DotLoader';
import styled from 'styled-components';

export const CustomLoader = styled(DotLoader)``;

export const LoaderContainer = styled.div`
  position: fixed;
  min-height: 100vh !important;
  width: 100% !important;
  z-index: 1102;
  left: 0;
  top: 0;

  > span {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    min-height: 100vh !important;
    width: 100% !important;
    background: white;
    opacity: 0.4;
  }
`;
