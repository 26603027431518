import App from './App/reducer';
import Bot from './Bot/reducer';
import Configurations from './Configurations/reducer';
import Loading from './Loading/reducer';
import Modal from './Modal/reducer';
import User from './User/reducer';
import { combineReducers } from 'redux';
import { createHashHistory } from 'history';
import { createRouterReducer } from '@lagunovsky/redux-react-router';

export const history = createHashHistory();

const reducers = combineReducers({
  router: createRouterReducer(history),
  App,
  Bot,
  Configurations,
  Modal,
  User,
  Loading,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
