/* eslint-disable prettier/prettier */
const LocalStorage = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItem: (pointer: string): any => {
    if (!pointer) {
      return false;
    }
    const item: any = localStorage.getItem(pointer);
    try {
      return JSON.parse(item);
    } catch (error: any) {
      return item;
    }
  },
  setItem: (pointer: string, value: string) => pointer && value && localStorage.setItem(pointer, value),
  removeItem: (pointer: string) => pointer && localStorage.removeItem(pointer),
};

export default LocalStorage;
