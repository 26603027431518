import { BrokenRelease } from '../../../../../../typings/states';
import brokenContentSchema from './content-schema.json';
import repairContentPatch from './content-repair-patch.json';
import nextRelease from '../../chatbox_edge_2023-08-08/chatbox_edge_2023-08-08';

const brokenRelease: BrokenRelease = {
  version: 'backend_edge_2023-08-08',
  brokenContentSchema: brokenContentSchema,
  repairContentPatch: repairContentPatch,
  nextRelease: nextRelease,
};

export default brokenRelease;
