import axios from 'axios';

export const getLocalFile = (url: string, name: string) =>
  axios({
    method: 'GET',
    responseType: 'blob',
    url,
  })
    .then((res) => res.data)
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
      a.remove();
      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    });

/**
 * Read file to return Base64
 * @param file
 * @returns
 */
export const readeFileAsBase64 = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });

export const formatBytes = (bytes, decimals = 2) => {
  if (!bytes) return null;
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
