import { DotActionsListItem, LoadingWording } from './Style';

import { ActionsProps } from './DotActions';
import { useTranslation } from 'react-i18next';

interface ActionProps {
  action: ActionsProps;
  setOpen: (value: boolean) => void;
}

const Action = ({ action, setOpen }: ActionProps) => {
  const { t } = useTranslation();

  const handleOnClick = (e) => {
    e.stopPropagation();
    if (!action.loading && !action.disabled && action.onClick) {
      setOpen(false);
      action.onClick();
    }
  };

  const renderLoadingWording = () => <LoadingWording>{t('general.loading')}</LoadingWording>;

  return (
    <DotActionsListItem
      withSeparator={action.withSeparator}
      disabled={action.disabled}
      loading={action.loading?.toString()}
      type={action.type}
      onClick={handleOnClick}
    >
      {action.loading ? renderLoadingWording() : action.title}
    </DotActionsListItem>
  );
};

export default Action;
