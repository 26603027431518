import Actions from 'src/Components/Actions/Actions';
import Input from 'src/Components/Input/Input';
import { MainContainer } from './Style';
import { UpdateNameProps } from './UpdateNameContainer';
import { useTranslation } from 'react-i18next';

const UpdateName = ({ actions, configName, setConfigName }: UpdateNameProps) => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <Input
        value={configName}
        onChange={(e) => setConfigName(e.target.value)}
        placeholder={t('configurations.import_config_name_placeholder')}
        label={t('configurations.import_config_name_label')}
      />
      <Actions actions={actions} />
    </MainContainer>
  );
};

export default UpdateName;
